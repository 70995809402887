import React from "react";
import "./Tab.css";
const Tab = ({ tabs, active, onChange }) => {
  const tabStyles = {
    0: "border-b border-b-success text-success",
    1: "border-b border-b-danger text-danger",
  };

  return (
    <div className="flex w-full overflow-hidden">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          onClick={() => onChange(tab.id)}
          className={`${
            tab.id === active && tabStyles[active]
          } text-[13px] leading-4 w-full h-11`}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export default Tab;
