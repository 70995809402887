import React from "react";
import {TVChartContainer} from '../../TVChartContainer/index';
const TradingChart = ({
  pairName,chartLoaded
}) => {
  return (
    <div
    id="chart-container"
    style={{
      transform: 'scale(0.7)',
      transformOrigin: 'left top',
      }}
    className="w-full tvcontainer_parent"

>
      <TVChartContainer
        symbol={pairName}
        theme={'dark'}
        chartLoaded={chartLoaded}
      />

    </div>
  );
};

export default TradingChart;
