import React, { useState } from "react";
import Tooltip from "components/Tooltip";

const PriceRouting = () => {
  const [opened, setOpened] = useState(false);
  const [selectedDex, setSelectedDex] = useState(1);
  const dexes = [
    {
      id: 1,
      label: "MUX",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M14.6385 12.6915C14.4022 12.4815 14.1003 12.3634 13.7853 12.3634C13.4309 12.3634 13.1027 12.5078 12.8534 12.7704C11.9346 13.7679 11.1076 14.1485 9.92626 14.1485C8.69241 14.1485 7.83922 13.7548 6.90727 12.7572C6.67101 12.5078 6.32974 12.3503 5.98846 12.3503C5.67344 12.3503 5.35841 12.4685 5.12214 12.6915C4.62336 13.1772 4.59711 13.9779 5.06964 14.4767C6.48724 15.9862 7.98361 16.6687 9.92626 16.6687C11.8295 16.6687 13.2997 15.9862 14.7041 14.4767C15.1898 13.9648 15.1504 13.1641 14.6385 12.6915Z"
            fill="url(#paint0_linear_524_81)"
          />
          <path
            d="M14.6171 10.1759C15.4073 10.1759 16.0479 9.53535 16.0479 8.74519C16.0479 7.95502 15.4073 7.31445 14.6171 7.31445C13.827 7.31445 13.1864 7.95502 13.1864 8.74519C13.1864 9.53535 13.827 10.1759 14.6171 10.1759Z"
            fill="url(#paint1_linear_524_81)"
          />
          <path
            d="M4.62539 9.95566C3.95596 9.54878 3.73282 8.66934 4.13972 7.99991L6.52866 4.02274C6.93556 3.35331 7.81501 3.13018 8.48444 3.53708C9.15386 3.94399 9.37701 4.82343 8.97009 5.49284L6.58116 9.45689C6.17426 10.1394 5.29481 10.3494 4.62539 9.95566Z"
            fill="url(#paint2_linear_524_81)"
          />
          <path
            d="M9.22865 9.95566C8.55924 9.54878 8.33609 8.66934 8.743 7.99991L11.132 4.02274C11.5388 3.35331 12.4183 3.13018 13.0877 3.53708C13.7571 3.94399 13.9803 4.82343 13.5734 5.49284L11.1976 9.45689C10.7907 10.1394 9.9112 10.3494 9.22865 9.95566Z"
            fill="url(#paint3_linear_524_81)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_524_81"
              x1="10.8564"
              y1="14.6078"
              x2="3.65889"
              y2="7.26775"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#5ACEFF" />
              <stop offset="1" stopColor="#00EEEA" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_524_81"
              x1="15.618"
              y1="9.7714"
              x2="9.03066"
              y2="3.05364"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#5ACEFF" />
              <stop offset="1" stopColor="#00EEEA" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_524_81"
              x1="12.5107"
              y1="12.8184"
              x2="5.92341"
              y2="6.10066"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#5ACEFF" />
              <stop offset="1" stopColor="#00EEEA" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_524_81"
              x1="14.8603"
              y1="10.5106"
              x2="8.273"
              y2="3.79281"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#5ACEFF" />
              <stop offset="1" stopColor="#00EEEA" />
            </linearGradient>
          </defs>
        </svg>
      ),
      disabled: false,
    },
    {
      id: 2,
      label: "GMX",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M18.3333 16.0757L10.0267 3.92902L1.66667 16.0757H13.3067L10.0267 11.4424L8.38667 13.8757H6.64001L10.0267 9.0157L14.8867 16.0757H18.3333Z"
            fill="url(#paint0_linear_524_137)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_524_137"
              x1="8.82002"
              y1="3.22902"
              x2="3.62667"
              y2="16.4557"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#03D1CF" stopOpacity="0.988235" />
              <stop offset="1" stopColor="#4E09F8" />
            </linearGradient>
          </defs>
        </svg>
      ),
      disabled: false,
    },
    {
      id: 3,
      label: "gTrade",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <g mask="url(#mask0_524_141)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.5457 10.8779L3.67235 11.0995C4.08395 11.8172 5.00212 12.0599 5.71977 11.6483L12.052 7.98617L12.4319 8.59828L12.6853 9.03098L12.7275 9.22095V15.7431C12.7275 16.5664 13.4029 17.2418 14.2261 17.2418H14.4793C15.3026 17.2418 15.978 16.5664 15.978 15.7431V5.46382C15.978 4.6934 15.5031 4.02852 14.8276 3.74357C14.205 3.4164 13.4662 3.42695 12.8541 3.77523L4.10505 8.83045C3.3874 9.24205 3.14467 10.1602 3.55627 10.8779H3.5457Z"
              fill="url(#paint0_linear_524_141)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.29114 7.82587C8.94548 7.73088 10.3913 7.85752 10.9929 7.99472C11.5944 8.13192 12.1327 8.56462 12.1327 8.56462C12.1327 8.56462 12.1116 8.55407 12.0799 8.54352C12.5338 8.74403 12.7237 9.2084 12.7237 9.2084C12.5232 6.99212 10.9612 5.33518 8.39668 6.33778L4.25963 8.72293C4.23851 8.73348 4.76619 8.33243 8.30169 7.8153L8.29114 7.82587ZM11.1829 8.52242C11.0773 8.55407 10.9612 8.60683 10.8346 8.67015C10.9507 8.60683 11.0668 8.55407 11.1829 8.52242Z"
              fill="url(#paint1_linear_524_141)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.49217 14.671L8.81398 13.3517L8.96173 13.2674C8.96173 13.2674 9.21503 13.1301 9.4261 13.2674C9.63718 13.4045 9.62662 13.584 9.62662 13.584L9.65828 16.5284C9.65828 16.5284 9.57385 16.7711 9.4261 16.8556C9.27835 16.94 9.18337 16.9611 8.95118 16.8873C8.15965 16.6234 6.756 16.0535 6.32332 15.2831C6.24943 15.1565 6.27053 15.0087 6.3022 14.9348C6.34442 14.7765 6.4394 14.7026 6.49217 14.671Z"
              fill="url(#paint2_linear_524_141)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.34254 10.1689C3.34254 10.1689 3.21589 9.49346 3.90187 8.94468C3.98631 8.88135 4.08129 8.81803 4.18682 8.76526C5.17887 8.18481 7.63789 7.92096 8.29222 7.82598C8.94656 7.731 10.3924 7.85763 10.994 7.99483C11.5955 8.13203 12.1338 8.56475 12.1338 8.56475C12.1338 8.56475 11.5111 8.29035 10.8462 8.67028C10.1813 9.05021 5.71712 11.6359 5.71712 11.6359C5.71712 11.6359 5.21054 11.8997 4.86227 11.8364C4.50344 11.7625 4.11296 11.7203 3.77524 11.256C3.71191 11.1504 3.66969 11.0977 3.58527 10.9499L3.44807 10.6649L3.37419 10.4011L3.35309 10.1689H3.34254Z"
              fill="url(#paint3_linear_524_141)"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_524_141"
              x1="9.66705"
              y1="3.50587"
              x2="9.66705"
              y2="17.2418"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#4DFC8D" />
              <stop offset="1" stopColor="#1FC3F1" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_524_141"
              x1="9.34033"
              y1="5.8971"
              x2="8.32716"
              y2="9.55925"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#216882" />
              <stop offset="0.298" stopColor="#3AA698" />
              <stop offset="0.5412" stopColor="#53E4AF" />
              <stop offset="1" stopColor="#53E4AF" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_524_141"
              x1="6.7985"
              y1="13.6665"
              x2="9.70078"
              y2="15.619"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#4DFC8D" />
              <stop offset="0.502" stopColor="#1FC3F1" />
              <stop offset="1" stopColor="#1FC3F1" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_524_141"
              x1="8.30936"
              y1="7.5"
              x2="6.77906"
              y2="12.017"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#216882" />
              <stop offset="1" stopColor="#53E4AF" />
            </linearGradient>
          </defs>
        </svg>
      ),
      disabled: true,
    },
  ];
  const handleToggleOpen = () => setOpened(!opened);

  return (
    <div className="w-full md:w-[285px] flex-shrink-0 bg-[#080808] border-l border-l-border-main">
      <div className="flex flex-col gap-5 px-[15px] py-[18px]">
        <div className="flex flex-col gap-3">
          <div className="flex items-center gap-2">
            <div className="flex-shrink-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <g clipPath="url(#clip0_514_28)">
                  <path
                    d="M35.5 0.5H5.5C3.01472 0.5 1 2.51472 1 5V35C1 37.4853 3.01472 39.5 5.5 39.5H35.5C37.9853 39.5 40 37.4853 40 35V5C40 2.51472 37.9853 0.5 35.5 0.5Z"
                    fill="#0F0F0F"
                  />
                  <path
                    d="M23.2788 12.6328H28.2769L23.3724 19.6189H18.6641L23.2788 12.6328Z"
                    stroke="url(#paint0_linear_514_28)"
                    strokeWidth="0.838327"
                  />
                  <path
                    d="M23.298 27.779H28.2815L23.4192 20.793H18.7246L23.298 27.779Z"
                    stroke="url(#paint1_linear_514_28)"
                    strokeWidth="0.838327"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.2109 12.2109H17.1945L21.8053 20.0353H17.1107L12.2109 12.2109ZM17.6975 28.223H12.2204L17.1107 20.3707H21.8053L17.6975 28.223Z"
                    fill="url(#paint2_linear_514_28)"
                  />
                  <g mask="url(#mask0_514_28)">
                    <path
                      d="M17.1939 12.216L17.242 12.1877L17.2258 12.1602H17.1939V12.216ZM12.2103 12.216V12.1602H12.1094L12.1629 12.2457L12.2103 12.216ZM21.8047 20.0404V20.0963H21.9025L21.8528 20.012L21.8047 20.0404ZM17.11 20.0404L17.0627 20.0701L17.0791 20.0963H17.11V20.0404ZM12.2198 28.2281L12.1724 28.1985L12.1192 28.284H12.2198V28.2281ZM17.6969 28.2281V28.284H17.7307L17.7464 28.254L17.6969 28.2281ZM17.11 20.3758V20.3199H17.079L17.0626 20.3462L17.11 20.3758ZM21.8047 20.3758L21.8542 20.4017L21.897 20.3199H21.8047V20.3758ZM17.1939 12.1602H12.2103V12.2719H17.1939V12.1602ZM21.8528 20.012L17.242 12.1877L17.1457 12.2444L21.7565 20.0688L21.8528 20.012ZM17.11 20.0963H21.8047V19.9845H17.11V20.0963ZM12.1629 12.2457L17.0627 20.0701L17.1574 20.0108L12.2577 12.1864L12.1629 12.2457ZM12.2198 28.284H17.6969V28.1722H12.2198V28.284ZM17.0626 20.3462L12.1724 28.1985L12.2673 28.2577L17.1575 20.4053L17.0626 20.3462ZM21.8047 20.3199H17.11V20.4317H21.8047V20.3199ZM17.7464 28.254L21.8542 20.4017L21.7552 20.3498L17.6473 28.2022L17.7464 28.254Z"
                      fill="url(#paint4_linear_514_28)"
                    />
                  </g>
                  <path
                    d="M35 0.5H5C2.51472 0.5 0.5 2.51472 0.5 5V35C0.5 37.4853 2.51472 39.5 5 39.5H35C37.4853 39.5 39.5 37.4853 39.5 35V5C39.5 2.51472 37.4853 0.5 35 0.5Z"
                    stroke="#151515"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_514_28"
                    x1="18.3994"
                    y1="16.185"
                    x2="28.2769"
                    y2="16.185"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#27F9BB" />
                    <stop offset="1" stopColor="#07F2C7" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_514_28"
                    x1="18.4615"
                    y1="24.2268"
                    x2="28.2815"
                    y2="24.2268"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#27F9BB" />
                    <stop offset="1" stopColor="#07F2C7" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_514_28"
                    x1="22.0695"
                    y1="20.3527"
                    x2="12.2109"
                    y2="20.3527"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#27F9BB" />
                    <stop offset="1" stopColor="#07F2C7" />
                  </linearGradient>
                  <linearGradient
                    id="paint3_linear_514_28"
                    x1="22.0675"
                    y1="20.3527"
                    x2="12.209"
                    y2="20.3527"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#27F9BB" />
                    <stop offset="1" stopColor="#07F2C7" />
                  </linearGradient>
                  <linearGradient
                    id="paint4_linear_514_28"
                    x1="22.0689"
                    y1="20.3578"
                    x2="12.2103"
                    y2="20.3578"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#27F9BB" />
                    <stop offset="1" stopColor="#07F2C7" />
                  </linearGradient>
                  <clipPath id="clip0_514_28">
                    <rect width="40" height="40" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="w-full flex items-center">
              <span className="w-full h-0.5 bg-primary" />
              <div className="flex-shrink-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                >
                  <g clipPath="url(#clip0_517_46)">
                    <path d="M15 0.5H0V15.5H15V0.5Z" fill="#080808" />
                    <path
                      d="M7.21425 4.98177L9.82967 7.91216C9.82956 7.91203 9.82978 7.91229 9.82967 7.91216C9.96056 8.05803 10.0309 8.25047 10.0309 8.44637C10.0309 8.64228 9.96087 8.83436 9.82996 8.98024C9.82986 8.98035 9.83005 8.98013 9.82996 8.98024L7.21425 11.911C7.14903 11.9837 7.06959 12.0433 6.98063 12.0842C6.89158 12.1252 6.79502 12.1466 6.69682 12.1466C6.59861 12.1466 6.50206 12.1252 6.413 12.0842C6.32404 12.0433 6.245 11.9841 6.17978 11.9114L7.21425 4.98177ZM7.21425 4.98177C7.14903 4.90905 7.0696 4.84943 6.98063 4.80852C6.89157 4.76758 6.79502 4.74609 6.69682 4.74609C6.59861 4.74609 6.50206 4.76758 6.413 4.80852C6.32403 4.84943 6.245 4.9086 6.17978 4.98132C6.04835 5.12708 5.97852 5.31886 5.97852 5.51499C5.97852 5.70942 6.04758 5.90006 6.17681 6.04535L8.18532 8.44672M7.21425 4.98177L8.18532 8.44672L7.21425 4.98177Z"
                      fill="#F3BA2F"
                    />
                    <path
                      d="M7.21425 4.98177L9.82967 7.91216M7.21425 4.98177L6.17978 11.9114C6.245 11.9841 6.32404 12.0433 6.413 12.0842C6.50206 12.1252 6.59861 12.1466 6.69682 12.1466C6.79502 12.1466 6.89158 12.1252 6.98063 12.0842C7.06959 12.0433 7.14903 11.9837 7.21425 11.911L9.82996 8.98024M7.21425 4.98177C7.14903 4.90905 7.0696 4.84943 6.98063 4.80852C6.89157 4.76758 6.79502 4.74609 6.69682 4.74609C6.59861 4.74609 6.50206 4.76758 6.413 4.80852C6.32403 4.84943 6.245 4.9086 6.17978 4.98132M7.21425 4.98177L8.18532 8.44672M9.82967 7.91216C9.82978 7.91229 9.82956 7.91203 9.82967 7.91216ZM9.82967 7.91216C9.96056 8.05803 10.0309 8.25047 10.0309 8.44637C10.0309 8.64228 9.96087 8.83436 9.82996 8.98024M9.82996 8.98024C9.82986 8.98035 9.83005 8.98013 9.82996 8.98024ZM6.17978 4.98132L6.36589 5.14824M6.17978 4.98132C6.04835 5.12708 5.97852 5.31886 5.97852 5.51499C5.97852 5.70942 6.04758 5.90006 6.17681 6.04535L8.18532 8.44672M8.18532 8.44672L6.17682 10.8474"
                      stroke="#F3BA2F"
                      strokeWidth="0.5"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_517_46">
                      <rect
                        width="15"
                        height="15"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <span className="w-full h-0.5 bg-primary" />
            </div>
            <div className="flex-shrink-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <g clipPath="url(#clip0_514_39)">
                  <path
                    d="M35 0.5H5C2.51472 0.5 0.5 2.51472 0.5 5V35C0.5 37.4853 2.51472 39.5 5 39.5H35C37.4853 39.5 39.5 37.4853 39.5 35V5C39.5 2.51472 37.4853 0.5 35 0.5Z"
                    fill="#0F0F0F"
                  />
                  <path
                    d="M29.8708 27.5499L20.0315 12.4531L10.1289 27.5499H23.9167L20.0315 21.7913L18.0889 24.8156H16.0199L20.0315 18.7752L25.7882 27.5499H29.8708Z"
                    fill="url(#paint0_linear_514_39)"
                  />
                  <path
                    d="M35 0.5H5C2.51472 0.5 0.5 2.51472 0.5 5V35C0.5 37.4853 2.51472 39.5 5 39.5H35C37.4853 39.5 39.5 37.4853 39.5 35V5C39.5 2.51472 37.4853 0.5 35 0.5Z"
                    stroke="#151515"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_514_39"
                    x1="18.6021"
                    y1="11.5831"
                    x2="11.9196"
                    y2="27.8034"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#03D1CF" stopOpacity="0.988235" />
                    <stop offset="1" stopColor="#4E09F8" />
                  </linearGradient>
                  <clipPath id="clip0_514_39">
                    <rect width="40" height="40" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div className="flex items-center justify-between px-1.5 opacity-50 text-xs">
            <span>DAOX</span>
            <span>GMX</span>
          </div>
        </div>
        <div className="w-full h-[1px] bg-border-main" />
        <div className="flex flex-col gap-[15px] text-[13px] text-white">
          <div className="flex items-center justify-between">
            <span className="opacity-50">DEX</span>
            <span
              className="flex items-center gap-1 relative z-50"
              onMouseEnter={handleToggleOpen}
              onMouseLeave={handleToggleOpen}
            >
              <span className="opacity-50">Fees</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="12"
                viewBox="0 0 11 12"
                fill="none"
              >
                <g clipPath="url(#clip0_524_75)">
                  <path
                    d="M5.50001 1.07471C4.50286 1.07471 3.52811 1.3704 2.69902 1.92438C1.86992 2.47837 1.22371 3.26577 0.84212 4.18701C0.460528 5.10826 0.360686 6.12196 0.55522 7.09996C0.749754 8.07794 1.22993 8.97628 1.93501 9.68133C2.6401 10.3864 3.53844 10.8667 4.51644 11.0612C5.49442 11.2557 6.50813 11.1559 7.42937 10.7743C8.35062 10.3927 9.13801 9.74651 9.69202 8.91737C10.246 8.08828 10.5417 7.11352 10.5417 6.11637C10.5402 4.7797 10.0085 3.4982 9.06337 2.55302C8.11819 1.60785 6.83668 1.07619 5.50001 1.07471ZM5.50001 10.1498C4.70229 10.1498 3.92249 9.91316 3.25921 9.46997C2.59594 9.02678 2.07897 8.39685 1.7737 7.65986C1.46843 6.92286 1.38855 6.1119 1.54418 5.32951C1.6998 4.54712 2.08394 3.82844 2.64801 3.26437C3.21208 2.7003 3.93076 2.31616 4.71314 2.16054C5.49554 2.00491 6.3065 2.08479 7.0435 2.39006C7.78049 2.69533 8.41042 3.2123 8.85361 3.87557C9.29679 4.53885 9.53334 5.31865 9.53334 6.11637C9.53212 7.1857 9.10679 8.21089 8.35066 8.96702C7.59453 9.72313 6.56934 10.1485 5.50001 10.1498ZM5.50001 5.86429C5.3663 5.86429 5.23806 5.91741 5.14351 6.01195C5.04896 6.10651 4.99584 6.23474 4.99584 6.36845V7.88096C4.99584 8.01467 5.04896 8.14291 5.14351 8.23746C5.23806 8.33201 5.3663 8.38512 5.50001 8.38512C5.63372 8.38512 5.76197 8.33201 5.85651 8.23746C5.95106 8.14291 6.00418 8.01467 6.00418 7.88096V6.36845C6.00418 6.23474 5.95106 6.10651 5.85651 6.01195C5.76197 5.91741 5.63372 5.86429 5.50001 5.86429ZM5.50001 3.84762C5.37537 3.84762 5.25353 3.88458 5.14989 3.95384C5.04625 4.02308 4.96547 4.1215 4.91777 4.23666C4.87008 4.35182 4.85759 4.47854 4.88191 4.60078C4.90623 4.72303 4.96625 4.83532 5.05439 4.92346C5.14252 5.0116 5.25481 5.07162 5.37707 5.09593C5.49931 5.12025 5.62602 5.10776 5.74119 5.06007C5.85634 5.01237 5.95476 4.9316 6.02401 4.82796C6.09326 4.72432 6.13022 4.60248 6.13022 4.47783C6.13022 4.31069 6.06382 4.1504 5.94564 4.03221C5.82745 3.91402 5.66716 3.84762 5.50001 3.84762Z"
                    fill="#828282"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_524_75">
                    <rect
                      width="11"
                      height="11"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              {opened && (
                <div className={`absolute -right-3 -bottom-3 translate-y-full z-[100]`}>
                  <Tooltip position="right">
                    Total fees required to place the trade
                  </Tooltip>
                </div>
              )}
            </span>
          </div>
          {dexes.map((dex) => (
            <div
              className={`flex items-center justify-between ${
                dex.disabled ? `opacity-50` : `opacity-100`
              }`}
              key={dex.id}
            >
              <label className="flex items-center gap-[5px]">
                <input
                  type="radio"
                  checked={selectedDex === dex.id}
                  onChange={() => setSelectedDex(dex.id)}
                  disabled={dex.disabled}
                />
                <div className="flex items-center gap-[2.5px]">
                  {dex.icon}
                  <span>{dex.label}</span>
                </div>
              </label>
              {dex.disabled && (
                <span className="decoration-dashed underline cursor-pointer">
                  ETH unsupported
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PriceRouting;
