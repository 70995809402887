import React, { useState } from "react";
import useIsMobile from "hooks/useIsMobile";
import Tooltip from "components/Tooltip";

const Item = (props) => {
  const isMobile = useIsMobile();
  const [opened, setOpened] = useState(false);
  const handleToggleOpen = () => setOpened(!opened);

  return (
    <li className="flex items-center justify-between text-[13px]">
      <div className="relative">
        <span
          className="opacity-50 underline decoration-dashed cursor-pointer"
          onMouseEnter={handleToggleOpen}
          onMouseLeave={handleToggleOpen}
        >
          {props.label}
        </span>
        {opened && (
          <div
            className={`absolute z-20 ${
              isMobile ? "left-0" : "-right-8"
            } -bottom-3 translate-y-full`}
          >
            <Tooltip position={isMobile ? "left" : "right"}>
              {props.tooltip}
            </Tooltip>
          </div>
        )}
      </div>
      <span>{props.value}</span>
    </li>
  );
};

const Summary = ({ liqprice, pairBorrow, pairInterest }) => {
  const data = [
    {
      id: 1,
      label: "Available Liquidity",
      value: pairBorrow,
      tooltip:
        "Please note when you use volatile assets as collateral, the liquidation price will be affected by both collateral and underlying asset prices.",
    },
    {
      id: 2,
      label: "Profits in",
      value: "USDT",
      tooltip:
        "If this position is closed, your realized profits will be settled in USDT.",
    },
    {
      id: 3,
      label: "Liquidation Price",
      value: liqprice,
      tooltip:
        "Please note when you use volatile assets as collateral, the liquidation price will be affected by both collateral and underlying asset prices.",
    },
    {
      id: 4,
      label: "Fees",
      value: `${pairInterest}% per hour`,
      tooltip:
        "The position fee will be collected when you open and close a position; it will be deducted from your collateral when opening the position and will be preferentially deducted from the profits when closing the position.",
    },
    {
      id: 5,
      label: "Spread",
      value: "0%",
      tooltip:
        "Spread is the difference between the ask price and index price, as well as the difference between the bid price and index price.",
    },
  ];

  return (
    <ul className="flex flex-col gap-[15px]">
      {data.map((item) => (
        <Item {...item} key={item.id} />
      ))}
    </ul>
  );
};

export default Summary;
