import React, { useState } from "react";

const SelectLeverage = ({ slider, handleSlider }) => {
  const [value, setValue] = useState(1);

  return (
    <div className="flex flex-col gap-5">
      <p className="text-[13px] opacity-50">Select Leverage up to 100x</p>
      <div className="flex items-center gap-5">
        <div className="w-full">
          <div className="relative flex items-center justify-between w-full h-2.5 rounded-full bg-[#0F0F0F] px-1 mb-2">
            <input
              type="range"
              min={1}
              max={100}
              step={0.1}
              value={slider}
              onChange={handleSlider}
              className="w-full z-10 absolute left-0"
            />
            <div
              className="h-full absolute left-0 top-0 bg-primary rounded-full"
              style={{ width: `calc(${(slider / 100) * 98}% + 4px)` }}
            ></div>
            <div className="w-1 h-1 rounded-full bg-white opacity-30"></div>
            <div className="w-1 h-1 rounded-full bg-white opacity-30"></div>
            <div className="w-1 h-1 rounded-full bg-white opacity-30"></div>
            <div className="w-1 h-1 rounded-full bg-white opacity-30"></div>
            <div className="w-1 h-1 rounded-full bg-white opacity-30"></div>
            <div className="w-1 h-1 rounded-full bg-white opacity-30"></div>
          </div>
          <div className="flex items-center justify-between -ml-[1px] -mr-2">
            <span className="text-xs opacity-50">1x</span>
            <span className="text-xs opacity-50">20x</span>
            <span className="text-xs opacity-50">40x</span>
            <span className="text-xs opacity-50">60x</span>
            <span className="text-xs opacity-50">80x</span>
            <span className="text-xs opacity-50">100x</span>
          </div>
        </div>
        <div className="w-[70px] h-[50px] flex items-center flex-shrink-0 border border-[#151515] bg-[#0F0F0F] rounded-[5px] px-2.5">
          <input
            value={slider}
            onChange={handleSlider}
            className="w-full text-center text-sm outline-none font-medium bg-transparent"
          />
          <span className="opacity-50 text-sm">X</span>
        </div>
      </div>
    </div>
  );
};

export default SelectLeverage;
