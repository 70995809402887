import React from "react";

const Button = ({ onClick, children, size = "sm", className }) => {
  const buttonStyles = {
    sm: "h-8 md:h-9 text-[10px] md:text-[13px] font-regular",
    lg: "h-[50px] text-base font-medium",
  };
  return (
    <button
      className={`${buttonStyles[size]} cursor-pointer text-dark px-[15px] bg-primary ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
