import React from "react";

const MenuItem = ({ icon, label, onClick, className }) => {
  return (
    <li
      className={`flex justify-end items-center gap-[5px] cursor-pointer ${className}`}
      onClick={onClick}
    >
      <span className="text-sm opacity-50">{label}</span>
      <div className="w-3 h-3 flex items-center">
        <img src={icon} />
      </div>
    </li>
  );
};

export default MenuItem;
