import React, { useState } from "react";
import Tooltip from "components/Tooltip";
import USDTTokenIcon from "assets/images/tokens/usdt.png";
import LockIcon from "assets/images/icons/lock.svg";
import CloseIcon from "assets/images/icons/close.svg";

const Item = (props) => {
  const [opened, setOpened] = useState(false);
  const handleToggleOpen = () => setOpened(!opened);

  return (
    <li className="flex items-center justify-between mb-[15px] last:mb-0">
      <div className="relative">
        <span
          className="text-[13px] leading-4 opacity-50 underline decoration-dashed cursor-pointer"
          onMouseEnter={handleToggleOpen}
          onMouseLeave={handleToggleOpen}
        >
          {props.label}
        </span>
        {opened && (
          <div className={`absolute z-20 left-0 -bottom-3 translate-y-full`}>
            <Tooltip position="left">{props.tooltip}</Tooltip>
          </div>
        )}
      </div>
      <span className="text-sm leading-[17px]">{props.value}</span>
    </li>
  );
};

const ClosePosition = ({ open, onClose }) => {
  const data = [
    {
      id: 1,
      label: "Entry Price",
      value: "$30,872.00",
      tooltip:
        "Please note when you use volatile assets as collateral, the liquidation price will be affected by both collateral and underlying asset prices.",
    },
    {
      id: 2,
      label: "Market Price",
      value: "$30,159.00",
      tooltip:
        "If this position is closed, your realized profits will be settled in USDT.",
    },
    {
      id: 3,
      label: "Leverage",
      value: "1X",
      tooltip:
        "Please note when you use volatile assets as collateral, the liquidation price will be affected by both collateral and underlying asset prices.",
    },
    {
      id: 4,
      label: "Collateral",
      value: "$9.9920",
      tooltip:
        "The position fee will be collected when you open and close a position; it will be deducted from your collateral when opening the position and will be preferentially deducted from the profits when closing the position.",
    },
  ];

  return (
    <>
      {open && (
        <div className="fixed z-20 w-screen h-screen top-0 left-0 flex items-center justify-center p-5">
          <div
            className="absolute w-full h-full bg-dark opacity-60"
            onClick={onClose}
          />
          <div className="w-full max-w-[450px] bg-[#121212] rounded-[20px] relative p-[25px]">
            <img
              src={CloseIcon}
              className="absolute top-[25px] right-[25px] cursor-pointer hover:opacity-60 transition"
              alt=""
              onClick={onClose}
            />
            <p className="text-xl mb-5">
              Close BTC Position{" "}
              <span className="text-success">- Long 1X</span>
            </p>
            <div className="grid grid-cols-2 gap-[5px] mb-[5px]">
              <div className="h-[76px] flex flex-col justify-between bg-dark rounded-[10px] px-5 py-[15px]">
                <p className="text-[13px] leadin-4 opacity-50">Market Price</p>
                <p className="font-medium text-base leading-[19px]">
                  $30594.00
                </p>
              </div>
              <div className="h-[76px] flex items-center justify-between bg-[#1A1A1A] rounded-[10px] px-5 py-[15px]">
                <img src={LockIcon} alt="" />
                <div className="h-full flex flex-col items-end justify-between">
                  <p className="text-[13px] leadin-4 opacity-50">Order Type</p>
                  <p className="font-medium text-base leading-[19px]">Market</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-between bg-dark rounded-[10px] px-5 py-[15px] mb-5">
              <div className="flex items-center justify-between text-[13px] leading-16 opacity-50 mb-4">
                <span>Close amount</span>
                <span>Max:0.000324</span>
              </div>
              <div className="flex justify-between mb-5">
                <span className="font-medium text-base">0.0</span>
                <div className="flex items-center">
                  <img
                    src={USDTTokenIcon}
                    className="w-[15px] h-[15px] rounded-full"
                  />
                  <span className="text-base leading-[19px] ml-[5px]">
                    USDT
                  </span>
                </div>
              </div>
              <div className="grid grid-cols-4 gap-2.5">
                <button className="w-full h-8 rounded-[5px] bg-[#252525] text-[15px] opacity-50">
                  25%
                </button>
                <button className="w-full h-8 rounded-[5px] bg-[#252525] text-[15px] opacity-50">
                  50%
                </button>
                <button className="w-full h-8 rounded-[5px] bg-[#252525] text-[15px] opacity-50">
                  75%
                </button>
                <button className="w-full h-8 rounded-[5px] bg-[#252525] text-[15px] opacity-50">
                  100%
                </button>
              </div>
            </div>
            <ul className="mb-5">
              {data.map((item) => (
                <Item {...item} key={item.id} />
              ))}
            </ul>
            <button
              className="w-full h-14 rounded-[10px] bg-[#252525]"
              onClick={onClose}
            >
              <span className="font-medium text-lg opacity-50">Confirm</span>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ClosePosition;
