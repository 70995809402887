import React from "react";
import MetamaskIcon from "assets/images/wallets/metamask.svg";
import WalletConnectIcon from "assets/images/wallets/wallet-connect.svg";

import { useWeb3React } from "@web3-react/core";
import { connectors } from "../connectors";

const ConnectWallet = ({ open, onClose }) => {
  const wallets = [
    { id: 1, label: "Metamask", icon: MetamaskIcon },
    { id: 2, label: "Wallet connect", icon: WalletConnectIcon },
  ];

  const { activate } = useWeb3React();
  const setProvider = (type) => {
    window.localStorage.setItem("provider", type);
  };

  const handleConnect = (index) => {
    if (index == 0) {
      setProvider("injected");
      setTimeout(() => {
        activate(connectors.injected);
      }, 500);
    } else if (index == 1) {
      setProvider("walletConnect");
      activate(connectors.walletConnect);
    }
    onClose();
  };

  return (
    <>
      {open && (
        <div className="fixed z-[100] w-screen h-screen top-0 left-0 flex items-end md:items-center justify-center">
          <div
            className="absolute w-full h-full bg-dark opacity-60"
            onClick={onClose}
          />
          <div className="w-full md:max-w-[375px] bg-black md:rounded-[10px] border border-[#282828] relative">
            <div className="h-[50px] flex items-center border-b border-b-[#282828]">
              <p className="w-full text-[15px] px-[25px]">Welcome to DAOX</p>
              <button
                className="w-[50px] h-full flex items-center justify-center flex-shrink-0 border-l border-l-[#282828]"
                onClick={onClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M4.44415 5.5673L5.56717 4.44428L9.99971 8.87683L14.4322 4.44428L15.5553 5.5673L11.1227 9.99981L15.5553 14.4324L14.4322 15.5554L9.99971 11.1229L5.56717 15.5554L4.44415 14.4324L8.8767 9.99981L4.44415 5.5673Z"
                    fill="#F2F2F2"
                  />
                </svg>
              </button>
            </div>
            <div className="max-h-[315px] overflow-auto mb-0">
              {wallets.map((wallet, i) => (
                <button
                  className="w-full h-[60px] bg-transparent hover:bg-[#F2F2F220] rounded-[5px] flex items-center px-[15px] gap-2.5 border-b border-b-[#282828] last:border-b-0"
                  onClick={() => handleConnect(i)}
                  key={wallet.id}
                >
                  <img
                    src={wallet.icon}
                    className="w-[30px] h-[30px]"
                    alt={wallet.label}
                  />
                  <span className="text-[15px]">{wallet.label}</span>
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConnectWallet;
