import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {toFixed,getBigNumber,timeConverter, numberFormat, truncateAddress} from "../../components/utils";
import "./style.css";

import { useWeb3React } from "@web3-react/core";
import {chainData,USDC_PRECISION} from "../../components/tradeUI"


import { useEffect,useState } from "react";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
};

export default function Tpsl({ 
  Tp,
  Sl,
  updateTPSL,
  index,
  setOpenModal,
  isOpen

  }) {
  //{ open, handleClose }
   
    const { activate ,account} = useWeb3React();
    const [mtp,setMtp] = useState(0);
    const [msl,setMsl] = useState(0);
    useEffect(()=>{
        const newTp = Number(Tp)/Math.pow(10,USDC_PRECISION);
        const newSl = Number(Sl)/Math.pow(10,USDC_PRECISION);
        setMtp(newTp);
        setMsl(newSl);
    },[Tp,Sl]);
    const confirm = ()=>{
        updateTPSL(mtp,msl,index);
        setOpenModal(false);
    }

  return (
    <Modal
      open={isOpen}
      onClose={()=>setOpenModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="sc-hAsxaJ ia-DxTe">
          <div className="sc-yeoIj fYAHzI">
            <div className="sc-caXVBt btrGPJ">Manage TP/SL</div>
            <div className="sc-bPyhqo iijVdv" onClick={()=>setOpenModal(false)}></div>
          </div>
          <div className="sc-gkJlnC eqDBwb"></div>
            <div className="sc-izEbhJ kqnjtX" style={{padding:"20px"}}>
              <div className="sc-lmHNfd bWgAxR">
                    <div className="Exchange-swap-section-top">
                        <div className="muted align-left">Take Profit</div>
                        <div className="muted align-right"></div>
                    </div> 
                    <div className="Exchange-swap-section-bottom">
                        <div className="Exchange-swap-input-container">
                            <input  min="0" value={mtp} onChange={(e)=>setMtp(e.target.value)} placeholder="0.0" className="tpslfield Exchange-swap-input" />
                        </div>
                    </div>
              </div>
              <div className="sc-lmHNfd bWgAxR">
                  <div className="Exchange-swap-section-top">
                      <div className="muted align-left">StopLoss</div>
                      <div className="muted align-right"></div>
                  </div>
                  <div className="Exchange-swap-section-bottom">
                      <div className="Exchange-swap-input-container">
                          <input  min="0" value={msl} onChange={(e)=>setMsl(e.target.value)}  placeholder="0.0" className="Exchange-swap-input tpslfield" />                            </div>
                  </div>
              </div>

              <button
                className="sc-fbPSWO sc-fzCEhR cYmVof kDKenN"
                onClick={()=>{setOpenModal(false);confirm();}}
              >
              CONFIRM
              </button>
              </div>
              
            </div>
      </Box>
    </Modal>
  );
}
