import React from "react";

const Tooltip = ({ children, position = "right" }) => {
  return (
    <div className="relative w-[280px] text-[13px] rounded-[10px] bg-dark border border-primary px-[15px] py-3">
      {children}
      <div className={`w-2.5 h-2.5 bg-primary absolute rotate-45 ${position === 'right' ? `right-8` : `left-8`} top-0 -translate-y-1/2`}>
        <div className="absolute w-2.5 h-2.5 bg-dark top-[1px] left-[1px]"></div>
      </div>
    </div>
  );
};

export default Tooltip;
