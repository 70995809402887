import React, { useCallback, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import MenuItem from "./MenuItem";

import ClipboardIcon from "assets/images/icons/clipboard.svg";
import ShareIcon from "assets/images/icons/share.svg";
import LogoutIcon from "assets/images/icons/logout.svg";
import tusdcIcon from "assets/images/icons/tusdc.svg";
import CaretIcon from "assets/images/icons/caret.svg";
import AccountIcon from "assets/images/icons/account.png";

import { useWeb3React } from "@web3-react/core";
import { USDC_ADDRESS, EXPLORER_LIST } from "../tradeUI.js";

import { truncateAddress } from "../utils";
import { getTideUsdc } from "../../api";
import { toast } from "react-toastify";

const Wallet = () => {
  const { chainId, account, deactivate } = useWeb3React();

  const [opened, setOpened] = useState(false);
  const handleToggleOpen = () => setOpened(!opened);

  const doReceiveTestUSDC = useCallback(() => {
    toast("Sent request to get 500tideUSDC.");
    getTideUsdc({ address: account, chainId: chainId }).then((res) => {
      if (res.message) {
        const dt = new Date(Number(res.message));
        toast("Please wait till " + dt.toLocaleString());
      } else {
        toast(`Got 500tideUsdc(${USDC_ADDRESS}).`);
      }

      console.log(res);
    });

    console.log("Send usdc");
  }, [account, chainId]);

  const handleDisconnect = () => {
    deactivate();
  };

  const items = [
    {
      id: 1,
      label: "Copy Address",
      icon: ClipboardIcon,
      action: () => {
        navigator.clipboard.writeText(account);
      },
    },
    {
      id: 2,
      label: "View in Explorer",
      icon: ShareIcon,
      action: () => {
        const url = EXPLORER_LIST[chainId] + `/address/${account}`;
        window.open(url, "_blank");
      },
    },
    {
      id: 3,
      label: "GET test USDC",
      icon: tusdcIcon,
      action: () => {
        doReceiveTestUSDC();
      },
    },
    {
      id: 4,
      label: "Disconnect",
      icon: LogoutIcon,
      action: () => handleDisconnect(),
    },
  ];

  const handleClick = (action) => {
    action();
    handleToggleOpen();
  };

  return (
    <div className="h-full flex items-center relative z-[100]">
      <button
        className="flex items-center justify-center gap-[5px]"
        onClick={handleToggleOpen}
      >
        <span className="text-sm">{truncateAddress(account)}</span>
        <img src={CaretIcon} />
      </button>
      {opened && (
        <OutsideClickHandler onOutsideClick={() => setOpened(false)}>
          <div className="z-[100] flex items-end w-screen h-screen md:h-auto fixed md:absolute bottom-0 md:translate-y-full right-0 md:w-[157px] rounded-none md:rounded-[5px] bg-[#000000BB] md:bg-[#121212] border border-[#282828] md:p-[15px]">
            <div className="w-full bg-black md:bg-transparent border-t border-t-[#282828] relative md:border-0">
              <div className="h-[50px] flex md:hidden items-center border-b border-b-[#282828]">
                <p className="w-full text-[15px] px-[15px]">Your Wallet</p>
                <button
                  className="w-[50px] h-full flex items-center justify-center flex-shrink-0 border-l border-l-[#282828]"
                  onClick={() => setOpened(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M4.44415 5.5673L5.56717 4.44428L9.99971 8.87683L14.4322 4.44428L15.5553 5.5673L11.1227 9.99981L15.5553 14.4324L14.4322 15.5554L9.99971 11.1229L5.56717 15.5554L4.44415 14.4324L8.8767 9.99981L4.44415 5.5673Z"
                      fill="#F2F2F2"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex flex-col gap-5 p-5 md:p-0">
                <div className="flex md:hidden items-center gap-2.5">
                  <img
                    src={AccountIcon}
                    className="w-[30px] h-[30px] rounded-full"
                  />
                  <span className="text-[13px] opacity-50">{account}</span>
                </div>
                <ul className="flex flex-col gap-5 md:gap-2.5">
                  {items.map((item) => (
                    <MenuItem
                      key={item.id}
                      {...item}
                      onClick={() => handleClick(item.action)}
                      className="last:hidden md:last:flex flex-row-reverse md:flex-row"
                    />
                  ))}
                </ul>
                <button
                  className="block md:hidden w-full p-3.5 text-[13px] text-primary bg-light-primary"
                  onClick={() => deactivate()}
                >
                  Disconnect
                </button>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default Wallet;
