// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shorttab
{
    background-color: #dc262654;
    border-color: #dc262654
}`, "",{"version":3,"sources":["webpack://./src/components/containers/Home/TradingControl/Tab.css"],"names":[],"mappings":"AAAA;;IAEI,2BAA2B;IAC3B;AACJ","sourcesContent":[".shorttab\n{\n    background-color: #dc262654;\n    border-color: #dc262654\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
