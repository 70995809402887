import React from "react";

const DataTable = ({ cols = [], rows = [], renderRow }) => {
  return (
    <table className="w-full">
      <thead>
        <tr>
          {cols.map((col, i) => (
            <td key={i} className="first:px-5 py-5 text-sm opacity-50">
              {col}
            </td>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows?.map((row,i) => (
          <React.Fragment key={i}>{renderRow(row,i)}</React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default DataTable;
