import React, { useState } from "react";
import ClosePositionModal from "components/Modal/ClosePosition";
import PositionCard from "./Card";

const MobileTradingPositions = () => {
  const [opened, setOpened] = useState(false);
  const handleToggleOpen = () => setOpened(!opened);
  const rows = [
    {
      id: 1,
      token: "btc",
      type: "pay",
      size: "$9.94",
      leverage: 1,
      netValue: "$9.99",
      entryPrice: "30872.00",
      markPrice: "$30723.00",
      liqPrice: null,
      pl: {
        value: -0.05,
        percent: -0.48,
      },
    },
    {
      id: 2,
      token: "btc",
      type: "buy",
      size: "$15.96",
      leverage: 1.6,
      netValue: "$9.99",
      entryPrice: "30749.00",
      markPrice: "$30723.00",
      liqPrice: 49723.55,
      pl: {
        value: 0.01,
        percent: 0.14,
      },
    },
  ];

  return (
    <div className="">
      <p className="px-5 text-[15px] leading-[18px] py-[30px]">
        Positions {rows.length > 0 && <span>({rows.length})</span>}
      </p>
      {rows.map((row) => (
        <div className="px-5 mb-5" key={row.id}>
          <PositionCard {...row} onClose={handleToggleOpen} />
        </div>
      ))}

      <ClosePositionModal open={opened} onClose={handleToggleOpen} />
    </div>
  );
};

export default MobileTradingPositions;
