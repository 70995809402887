import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Alert from "./Alert";

const Layout = ({setLibrary,setActive,setSelectedChainId}) => {
  return (
    <div className="min-h-screen font-basier text-white bg-dark">
      <Header setLibrary={setLibrary} setActive={setActive} setSelectedChainId={setSelectedChainId}/>
      <Alert />
      <Outlet />
    </div>
  );
};

export default Layout;
