import React, { useState } from "react";
import Tooltip from "components/Tooltip";
import USDTTokenIcon from "assets/images/tokens/usdt.png";
import LockIcon from "assets/images/icons/lock.svg";
import CloseIcon from "assets/images/icons/close.svg";
import {
  toFixed,
  getBigNumber,
  timeConverter,
  numberFormat,
  truncateAddress,
} from "../../components/utils";

const Item = (props) => {
  const [opened, setOpened] = useState(false);
  const handleToggleOpen = () => setOpened(!opened);

  return (
    <li className="flex items-center justify-between mb-[15px] last:mb-0">
      <div className="relative">
        <span
          className="text-[13px] leading-4 opacity-50 underline decoration-dashed cursor-pointer"
          onMouseEnter={handleToggleOpen}
          onMouseLeave={handleToggleOpen}
        >
          {props.label}
        </span>
        {opened && (
          <div className={`absolute z-20 left-0 -bottom-3 translate-y-full`}>
            <Tooltip position="left">{props.tooltip}</Tooltip>
          </div>
        )}
      </div>
      <span className="text-sm leading-[17px]">{props.value}</span>
    </li>
  );
};

const TradeConfirm = ({
  open,
  onClose,
  entryprice,
  bscdecimals,
  fromAmount,
  slider,
  fromSymbol,
  swapOption,
  slippage,
  handleOpenPosition,
  marketprice,
}) => {
  const data = [
    {
      id: 1,
      label: "Entry Price",
      value: `$${numberFormat(entryprice, bscdecimals)}`,
      tooltip:
        "Please note when you use volatile assets as collateral, the liquidation price will be affected by both collateral and underlying asset prices.",
    },
    {
      id: 2,
      label: "Market Price",
      value: `$${numberFormat(marketprice, bscdecimals)}`,
      tooltip:
        "If this position is closed, your realized profits will be settled in USDT.",
    },
    {
      id: 3,
      label: "Leverage",
      value: `${slider}X`,
      tooltip:
        "Please note when you use volatile assets as collateral, the liquidation price will be affected by both collateral and underlying asset prices.",
    },
    {
      id: 4,
      label: "Collateral",
      value: `$${numberFormat(fromAmount, 2)}`,
      tooltip:
        "The position fee will be collected when you open and close a position; it will be deducted from your collateral when opening the position and will be preferentially deducted from the profits when closing the position.",
    },
  ];
  const onConfirm = () => {
    handleOpenPosition();
    onClose();
  };
  return (
    <>
      {open && (
        <div className="fixed z-[100] w-screen h-screen top-0 left-0 flex items-end md:items-center justify-center">
          <div
            className="absolute w-full h-full bg-dark opacity-60"
            onClick={onClose}
          />
          <div className="w-full md:max-w-[375px] bg-black md:rounded-[10px] border border-[#282828] relative">
            <div className="h-[50px] flex items-center border-b border-b-[#282828]">
              <p className="w-full text-[15px] px-[25px]">
                Open {fromSymbol} Position
                <span
                  className={swapOption == 0 ? "text-success" : "text-danger"}
                >
                  {` `}- {swapOption == 0 ? "Long" : "Short"} {slider}X
                </span>
              </p>
              <button
                className="w-[50px] h-full flex items-center justify-center flex-shrink-0 border-l border-l-[#282828]"
                onClick={onClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M4.44415 5.5673L5.56717 4.44428L9.99971 8.87683L14.4322 4.44428L15.5553 5.5673L11.1227 9.99981L15.5553 14.4324L14.4322 15.5554L9.99971 11.1229L5.56717 15.5554L4.44415 14.4324L8.8767 9.99981L4.44415 5.5673Z"
                    fill="#F2F2F2"
                  />
                </svg>
              </button>
            </div>
            <div className="p-[15px]">
              <ul className="mb-5">
                {data.map((item) => (
                  <Item {...item} key={item.id} />
                ))}
              </ul>
              <button
                className="w-full h-12 rounded bg-[#252525]"
                onClick={onConfirm}
              >
                <span className="font-medium text-sm opacity-50">Confirm</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TradeConfirm;
