import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import MenuItem from "./MenuItem";
import CaretIcon from "assets/images/icons/caret.svg";
import ShareIcon from "assets/images/icons/share.svg";
import { USDC_ADDRESS, EXPLORER_LIST } from "../tradeUI.js";

import BNBIcon from "assets/images/icons/bnb_test.svg";
import ARBITRUMIcon from "assets/images/icons/arbitrum_test.svg";
import OPTIMISMIcon from "assets/images/icons/optimism_test.svg";
import FUJIIcon from "assets/images/icons/fuji_test.svg";
import BASEIcon from "assets/images/icons/base_test.svg";

import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { chainData, chainTitle } from "..//tradeUI";
const web3 = new Web3();

const ChooseNetwork = ({ className, setLibrary, setSelectedChainId }) => {
  const { library, active, chainId, account } = useWeb3React();
  const [isMetamask, setIsMetamask] = useState(false);

  const [opened, setOpened] = useState(false);
  const handleToggleOpen = () => setOpened(!opened);
  async function changeNetwork(_chainId) {
    if (library.connection.url != "metamask") {
      toast.error("Please change network to BNB testnet", {
        icon: ({ theme, type }) => (
          <img src="/assets/image/icon/ic-error.svg" />
        ),
      });
      return;
    }
    try {
      await library.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: web3.utils.toHex(_chainId) }],
      });
    } catch (switchError) {
      try {
        await library.provider.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainName: chainData[_chainId].chainName,
              chainId: web3.utils.toHex(_chainId),
              nativeCurrency: chainData[_chainId].nativeCurrency,
              rpcUrls: chainData[_chainId].rpcUrls,
            },
          ],
        });
      } catch (e) {
        console.log(e);
      }
    }
  }

  useEffect(() => {
    if (active) {
      setLibrary(library);

      setIsMetamask(library.connection.url === "metamask");
      let _chainId;
      if (
        chainId !== 97 &&
        chainId !== 43113 &&
        chainId !== 420 &&
        chainId !== 421613 &&
        chainId !== 84531
      ) {
        changeNetwork(97);
      } else {
        return;
      }
    } else {
      setIsMetamask(false);
      window.localStorage.setItem("provider", "");
    }
  }, [active, chainId, account, library]);

  const items = [
    { id: 1, label: "Arbitrum explorer", icon: ShareIcon },
    { id: 2, label: "Arbitrum token bridge", icon: ShareIcon },
  ];
  const networkIcon = {
    97: BNBIcon,
    43113: FUJIIcon,
    420: OPTIMISMIcon,
    421613: ARBITRUMIcon,
    84531: BASEIcon,
  };

  const handleClick = (chain) => {
    changeNetwork(chain);
    handleToggleOpen();
  };

  const handleOpenExplorer = (event, chain) => {
    event.stopPropagation();
    const url = EXPLORER_LIST[chain] + `/address/${account}`;
    window.open(url, "_blank");
  };

  return (
    <div className={`h-full relative ${className}`}>
      <button
        className="flex items-center justify-center h-full rounded-[5px]"
        onClick={handleToggleOpen}
      >
        <img
          src={networkIcon[chainId] ? networkIcon[chainId] : BNBIcon}
          className="w-[15px] h-[15px] rounded-full"
        />
        <span className="hidden md:inline text-sm ml-2.5">
          {chainId
            ? chainId === 97 ||
              chainId === 43113 ||
              chainId === 420 ||
              chainId === 84531 ||
              chainId === 421613
              ? chainTitle[chainId]
              : "WRONG NETWORK"
            : "BNB chain[test]"}
        </span>
        <img src={CaretIcon} className="ml-[5px]" />
      </button>
      {opened && (
        <OutsideClickHandler onOutsideClick={() => setOpened(false)}>
          <div className="z-[100] flex items-end w-screen h-screen md:h-auto fixed md:absolute bottom-0 md:translate-y-full right-0 md:w-[240px] rounded-none md:rounded-[5px] bg-[#000000BB] md:bg-[#121212] border border-[#282828] md:p-[15px]">
            <div className="w-full bg-black md:bg-transparent border-t border-t-[#282828] relative md:border-0">
              <div className="h-[50px] flex md:hidden items-center border-b border-b-[#282828]">
                <p className="w-full text-[15px] px-[15px]">Choose Network</p>
                <button
                  className="w-[50px] h-full flex items-center justify-center flex-shrink-0 border-l border-l-[#282828]"
                  onClick={() => setOpened(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M4.44415 5.5673L5.56717 4.44428L9.99971 8.87683L14.4322 4.44428L15.5553 5.5673L11.1227 9.99981L15.5553 14.4324L14.4322 15.5554L9.99971 11.1229L5.56717 15.5554L4.44415 14.4324L8.8767 9.99981L4.44415 5.5673Z"
                      fill="#F2F2F2"
                    />
                  </svg>
                </button>
              </div>
              <div className="flex flex-col p-[15px] md:p-0 md:gap-5">
                <ul className="grid grid-cols-1 md:gap-[15px]">
                  {Object.keys(chainData).map((chain, i) => {
                    return (
                      <li
                        key={i}
                        className="cursor-pointer border-b border-b-border-main last:border-b-0 md:border-b-0"
                        onClick={() => handleClick(chain)}
                      >
                        <div
                          className={`flex flex-col gap-5 p-5 md:p-0 rounded-[5px] ${
                            chainId === chain * 1
                              ? `bg-light-primary border border-primary md:bg-transparent md:border-0`
                              : ``
                          }`}
                        >
                          <div className="flex flex-row-reverse md:flex-row items-center justify-end gap-2.5 md:gap-[5px]">
                            <span className="text-[15px] md:text-sm">
                              {chainData[chain].chainName}
                            </span>
                            <img
                              src={networkIcon[chain]}
                              className="w-6 h-6 md:w-[17px] md:h-[17px]"
                            />
                          </div>
                          <ul className="flex items-center gap-5 md:hidden">
                            <li
                              className="flex items-center gap-[5px]"
                              onClick={(e) => handleOpenExplorer(e, chain)}
                            >
                              <span className="text-xs opacity-50">
                                {chainData[chain].chainName} Explorer
                              </span>
                              <img src={ShareIcon} />
                            </li>
                            <li className="flex items-center gap-[5px]">
                              <span className="text-xs opacity-50">
                                {chainData[chain].chainName} Token Bridge
                              </span>
                              <img src={ShareIcon} />
                            </li>
                          </ul>
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <div className="hidden md:block w-full h-[1px] bg-[#F2F2F2] opacity-10" />
                <ul className="hidden md:flex flex-col gap-2.5">
                  {items.map((item) => (
                    <MenuItem
                      key={item.id}
                      {...item}
                      onClick={() => handleClick(item.action)}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default ChooseNetwork;
