import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppContext from "context";
import Home from "pages/Home";
import Layout from "components/Layout";

import { ethers } from "ethers";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Web3ReactProvider } from "@web3-react/core";

const getLibrary = (provider) => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = 8000;
  return library;
};

function App() {
  const [slipMenu, setSlip] = useState(false);
  const [isactive, setActive] = useState(false);
  const [library, setLibrary] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false);
  const [selectedChainId, setSelectedChainId] = useState();
  const [address, setAddress] = useState("");
  const [isControlCentreOpen, setIsControlCentreOpen] = useState(false);
  const [needRegist, setNeedRegist] = useState(false);

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <BrowserRouter>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          style={{ zIndex: 999999999 }}
        />

        <Routes>
          <Route
            path="/"
            element={
              <Layout
                setLibrary={setLibrary}
                setActive={setActive}
                setSelectedChainId={setSelectedChainId}
              />
            }
          >
            <Route
              index
              element={
                <Home
                  library={library}
                  walletAddress={address}
                  selectedChainId={selectedChainId}
                  slippageMenu={slipMenu}
                  setSlippage={setSlip}
                  active={isactive}
                  setOpenModal={setOpenModal}
                  isControlCentreOpen={isControlCentreOpen}
                />
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </Web3ReactProvider>
  );
}

export default App;
