import BTCTokenIcon from "assets/images/tokens/btc.png";
import USDCTokenIcon from "assets/images/tokens/usdc.png";
import LINKTokenIcon from "assets/images/tokens/link.webp";
import UNITokenIcon from "assets/images/tokens/uni.webp";
import USDTTokenIcon from 'assets/images/tokens/usdt.png'
import ARBTokenIcon from "assets/images/tokens/arb.webp";

export const tokens = {
  btc: {
    label: "BTC",
    icon: BTCTokenIcon,
  },
  usdt: {
    label: "USDT",
    icon: USDTTokenIcon
  },
  usdc: {
    label: "USDC",
    icon: USDCTokenIcon,
  },
  link: {
    label: "LINK",
    icon: LINKTokenIcon,
  },
  uni: {
    label: "UNI",
    icon: UNITokenIcon,
  },
  arb: {
    label: "ARB",
    icon: ARBTokenIcon,
  },
  arb1: {
    label: "ARB",
    icon: ARBTokenIcon,
  },
  arb2: {
    label: "ARB",
    icon: ARBTokenIcon,
  },
  arb3: {
    label: "ARB",
    icon: ARBTokenIcon,
  },
  arb4: {
    label: "ARB",
    icon: ARBTokenIcon,
  }
};
