// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 992px) {
  .TVChartContainer {
    height: 680px;
    width: 142%;
  }
  .tvcontainer_parent{
    height: 478px;
  }
}
@media screen and (max-width: 991px) {
  .TVChartContainer {
    height: 750px;
    width: 142%;
  }
  .tvcontainer_parent{
    height: 525px;
  }
}

`, "",{"version":3,"sources":["webpack://./src/components/TVChartContainer/index.css"],"names":[],"mappings":"AAAA;EACE;IACE,aAAa;IACb,WAAW;EACb;EACA;IACE,aAAa;EACf;AACF;AACA;EACE;IACE,aAAa;IACb,WAAW;EACb;EACA;IACE,aAAa;EACf;AACF","sourcesContent":["@media screen and (min-width: 992px) {\n  .TVChartContainer {\n    height: 680px;\n    width: 142%;\n  }\n  .tvcontainer_parent{\n    height: 478px;\n  }\n}\n@media screen and (max-width: 991px) {\n  .TVChartContainer {\n    height: 750px;\n    width: 142%;\n  }\n  .tvcontainer_parent{\n    height: 525px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
