import React, { useState, useRef, useEffect } from "react";
import DataTable from "components/DataTable";
import ClosePositionModal from "components/Modal/ClosePosition";
import SymbolIcon from "assets/images/icons/symbol.svg";
import PositionCard from "./Card";

import { useWeb3React } from "@web3-react/core";
import * as ethers from "ethers";
import {
  numberFormat,
  getBigNumber,
  timeConverter,
  truncateAddress,
} from "components/utils";
import Web3 from "web3";
import {
  chainData,
  TOKENCONVERTOR,
  TIDEPOSITION_ADDRESS,
  ZEROLAYER_ENDPOINT_LIST,
  ZEROLAYER_CHAINID_LIST,
  BSCRPCURL,
  TRADING_ADDRESS_LIST,
  MAX_TRADES_PER_PAIR,
  STORAGE_ADDRESS,
  LIMIT_ADDRESS_LIST,
  USDC_PRECISION,
} from "components/tradeUI.js";
import { STORAGE_ABI } from "abi/STORAGE";
import { TRADING_ABI } from "abi/TRADING";
import { LIMIT_ABI } from "abi/LIMIT";
import { ZERO_ABI } from "abi/ZERO";
import Tpsl from "modals/Tpsl";

import SharePosition from "modals/SharePosition";
import { toast } from "react-toastify";

import DatePicker from "react-datepicker";
import classNames from "classnames";
import { getTrades } from "api";
import { tokenMenu2 } from "components/tokens";

const TradingPositions = ({
  isMobile,
  selectedChainId,
  price,
  txs,
  outerActions,
  slippageP,
  pairIndex,
  tokens,
  handleToken,
  pairName,
  bscdecimals,
  activeWallet,
}) => {
  const [selectedTab, setSelectedTab] = useState("positions");
  const tabs = [
    { id: "positions", label: "Positions" },
    { id: "orders", label: "Open Orders" },
    { id: "trades", label: "Trades" },
  ];
  const [opened, setOpened] = useState(false);
  const handleToggleOpen = () => setOpened(!opened);
  const cols = [
    "Market",
    "Size",
    "Net value",
    "Entry price",
    "Market price",
    "Liq. price",
    "P&L",
    "Actions",
  ];
  const rows = [
    {
      id: 1,
      token: "btc",
      type: 1,
      size: "$9.94",
      leverage: 1,
      netValue: "$9.99",
      entryPrice: "30872.00",
      markPrice: "$30723.00",
      liqPrice: null,
      pl: {
        value: -0.05,
        percent: -0.48,
      },
    },
    {
      id: 2,
      token: "btc",
      type: 0,
      size: "$15.96",
      leverage: 1.6,
      netValue: "$9.99",
      entryPrice: "30749.00",
      markPrice: "$30723.00",
      liqPrice: 49723.55,
      pl: {
        value: 0.01,
        percent: 0.14,
      },
    },
  ];
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const { account, active, chainId, library } = useWeb3React();
  const [bottomOption, setBottomOption] = useState(1);
  const [tradeInfoRow, setTradeInfoRow] = useState({});
  const [tradeInfo, setTradeInfo] = useState({});
  const [limitsInfo, setLimitsInfo] = useState({ limits: [] });
  const [actions, setActions] = useState(0);
  const [isOpen, setOpenModal] = useState(false);
  const [isOpenShare, setOpenModalShare] = useState(false);

  const [sideShare, setSideShare] = useState("Long");
  const [leverageShare, setLeverageShare] = useState(20);
  const [symbolShare, setSymbolShare] = useState("");
  const [entryShare, setEntryShare] = useState(0);
  const [lastShare, setLastShare] = useState(0);
  const [profitShare, setProfitShare] = useState(0);

  const [index, setIndex] = useState(0);
  const [tp, setTp] = useState(0);
  const [sl, setSl] = useState(0);

  const [histories, setHistories] = useState([]);
  const [hPairIndex, setHPairIndex] = useState(-1);
  const [hSide, setHSide] = useState("Both");
  const [positionPairList, setPositionPairList] = useState([
    { value: pairIndex, item: pairName },
  ]);
  const prevAmount = usePrevious({
    selectedChainId,
    account,
    active,
    pairIndex,
    actions,
    outerActions,
    chainId,
  });
  async function updateHistories(_fromDate = 0) {
    let fromDate = _fromDate;
    if (Number(fromDate) >= 0) {
      var d = new Date();
      d.setDate(d.getDate() - Number(_fromDate));
      fromDate = d.toISOString().substring(0, 10);
    } else {
      fromDate = "";
    }

    getTrades({
      trader: account.toLowerCase(),
      pairIndex: hPairIndex,
      side: hSide,
      fromDate: fromDate,
    }).then((res) => {
      let _histories = [];
      res.map((trade) => {
        _histories.push(trade);
      });
      setHistories(_histories);
      console.log(_histories);
    });
  }
  function formatDecimals(value, decimals, fix = 2) {
    let v = Number(value) / Math.pow(10, decimals);
    return v;
  }
  function formatDate(data) {
    return data.replace("T", " ").substring(0, 19);
  }
  useEffect(() => {
    if (!active) {
      setHistories([]);
      return;
    }
    console.log("updatehistories", { active, account, prevAmount });
    if (active && account && (!prevAmount || prevAmount?.account != account)) {
      updateHistories(30);
    }
  }, [active, account]);
  //update position
  useEffect(() => {
    let data = { ...tradeInfo };
    if (!selectedChainId || !active || !account) {
      setTradeInfo({});
      console.log("cleared state");
      return;
    }
    const updater = async () => {
      let positions = [];
      let positionInfos = [];
      let orders = [];
      let trades = [];
      const before = Date.now();
      const web3 = new Web3(BSCRPCURL);
      const storageContract = new web3.eth.Contract(
        STORAGE_ABI,
        STORAGE_ADDRESS
      );
      for (let i = 0; i < MAX_TRADES_PER_PAIR; i++) {
        const position = await storageContract.methods
          .openTrades(account, pairIndex, i)
          .call();
        if (
          Number(position.leverage) > 0 &&
          position.trader.toLowerCase() == account.toLowerCase()
        ) {
          positions.push(position);
          const positionInfo = await storageContract.methods
            .openTradesInfo(account, pairIndex, i)
            .call();
          positionInfos.push(positionInfo);
        }
      }
      for (let i = 0; i < MAX_TRADES_PER_PAIR; i++) {
        const has = await storageContract.methods
          .hasOpenLimitOrder(account, pairIndex, i)
          .call();
        if (!has) break;
        const orderId = await storageContract.methods
          .openLimitOrderIds(account, pairIndex, i)
          .call();
        const order = await storageContract.methods
          .openLimitOrders(orderId)
          .call();
        orders.push(order);
      }
      if (data[pairIndex] == undefined) data[pairIndex] = {};
      data[pairIndex].positions = positions;
      data[pairIndex].orders = orders;
      data[pairIndex].positionInfos = positionInfos;
      data[pairIndex].trades = trades;
      console.log(data);
      setTradeInfo(data);
      console.log("Call update completed", Date.now() - before);
    };
    if (
      selectedChainId &&
      (!prevAmount?.selectedChainId ||
        prevAmount?.account != account ||
        prevAmount?.active != active ||
        prevAmount?.pairIndex != pairIndex ||
        prevAmount?.actions != actions ||
        prevAmount?.outerActions != outerActions)
    ) {
      updater();
    }
  }, [account, active, pairIndex, actions, outerActions, selectedChainId]);
  useEffect(() => {
    if (!selectedChainId || !active || !account) {
      setPositionPairList([]);
      return;
    }
    const updater = async () => {
      let pairs = [];
      const web3 = new Web3(BSCRPCURL);
      const storageContract = new web3.eth.Contract(
        STORAGE_ABI,
        STORAGE_ADDRESS
      );
      await Promise.all(
        tokens.map(async (token) => {
          const opentradescount = await storageContract.methods
            .openTradesCount(account, token.pairIndex)
            .call();
          if (
            opentradescount > 0 ||
            Number(token.pairIndex) == Number(pairIndex)
          ) {
            pairs.push({
              value: token.pairIndex,
              item: token.pair + `(${opentradescount})`,
            });
          }
        })
      );
      console.log("positionPair", { pairs });
      setPositionPairList(pairs);
    };
    if (
      selectedChainId &&
      (!prevAmount?.selectedChainId ||
        prevAmount?.account != account ||
        prevAmount?.active != active ||
        prevAmount?.actions != actions)
    ) {
      updater();
    }
  }, [account, active, pairIndex, actions, outerActions, selectedChainId]);
  useEffect(() => {
    let data = { ...limitsInfo };
    if (!active || !account) {
      data.limits = [];
      setLimitsInfo(data);
      console.log("cleared state");
      return;
    }

    let limits = [];
    const updater = async () => {
      console.log("Call update");
      const web3limit = new Web3(
        library.provider || chainData[this.props.selectedChainId].rpcUrls[0]
      );
      const limitContract = new web3limit.eth.Contract(
        LIMIT_ABI,
        LIMIT_ADDRESS_LIST[chainId]
      );
      const ctOrders = await limitContract.methods
        .getOrdersForAddressLength(account)
        .call();
      for (let i = 0; i < ctOrders; i++) {
        const orderI = await limitContract.methods
          .getOrderIdForAddress(account, i)
          .call();
        const order = await limitContract.methods.orderBook(orderI).call();
        if (order.orderState == 0) {
          limits.push(order);
        }
      }
      data.limits = limits;
      setLimitsInfo(data);
    };
    if (
      selectedChainId == 97 &&
      (prevAmount?.selectedChainId != 97 ||
        prevAmount?.account != account ||
        prevAmount?.active != active ||
        prevAmount?.actions != actions ||
        prevAmount?.outerActions != outerActions)
    ) {
      if (selectedChainId == 97) {
        updater();
      }
    }
  }, [account, active, pairIndex, actions, outerActions, selectedChainId]);
  async function selectPair(_pairIndex) {
    tokens.map((token) => {
      if (token.pairIndex == _pairIndex) {
        handleToken(token.address, token.pair, token.symbol, token.pairIndex);
      }
    });
  }
  useEffect(() => {
    let data = { ...limitsInfo };
    if (selectedChainId != 97 || !active || !account) {
      data.limits = [];
      setLimitsInfo(data);
      console.log("cleared state");
      return;
    }

    let limits = [];
    const updater = async () => {
      console.log("Call update");
      const web3limit = new Web3(
        library.provider || chainData[this.props.selectedChainId].rpcUrls[0]
      );
      const limitContract = new web3limit.eth.Contract(
        LIMIT_ABI,
        LIMIT_ADDRESS_LIST[chainId]
      );
      const ctOrders = await limitContract.methods
        .getOrdersForAddressLength(account)
        .call();
      for (let i = 0; i < ctOrders; i++) {
        const orderI = await limitContract.methods
          .getOrderIdForAddress(account, i)
          .call();
        const order = await limitContract.methods.orderBook(orderI).call();
        if (order.orderState == 0) {
          limits.push(order);
        }
      }
      data.limits = limits;
      setLimitsInfo(data);
    };
    if (
      selectedChainId == 97 &&
      (prevAmount?.selectedChainId != 97 ||
        prevAmount?.account != account ||
        prevAmount?.active != active ||
        prevAmount?.actions != actions ||
        prevAmount?.outerActions != outerActions)
    ) {
      if (selectedChainId == 97) {
        updater();
      }
    }
  }, [account, active, pairIndex, actions, outerActions, selectedChainId]);

  async function sharePosition(pairIndex, index) {
    const position = tradeInfo[pairIndex].positions[index];
    setSideShare(position.buy ? "Long" : "Short");
    setLeverageShare(position.leverage);
    setSymbolShare(tokens[pairIndex].symbol);

    setEntryShare(
      numberFormat(
        formatDecimals(position.openPrice, USDC_PRECISION),
        tokens[position.pairIndex].bscdecimals
      )
    );
    setLastShare(numberFormat(price, tokens[pairIndex].bscdecimals));
    const collateral = formatDecimals(position.positionSizeDai, USDC_PRECISION);
    const cur_profit = profit(
      position.buy,
      tradeInfo[pairIndex].positionInfos[index],
      Number(position.openPrice) / Math.pow(10, USDC_PRECISION)
    );

    console.log({ collateral, cur_profit });
    setProfitShare((Number(cur_profit) / Number(collateral)) * 100);

    setOpenModalShare(true);
  }

  async function updateTPSL(tp, sl, index) {
    toast.info("Started to update TP/SL.", {
      icon: ({ theme, type }) => <img src="/assets/image/icon/ic-info.svg" />,
    });
    const web3 = new Web3(
      library.provider || chainData[this.props.selectedChainId].rpcUrls[0]
    );
    const tradingContract = new web3.eth.Contract(
      TRADING_ABI,
      TRADING_ADDRESS_LIST[selectedChainId]
    );
    const btp = getBigNumber(tp * Math.pow(10, USDC_PRECISION));
    const bsl = getBigNumber(sl * Math.pow(10, USDC_PRECISION));
    const _index = tradeInfo[pairIndex]?.positions[index].index;

    let value;
    if (selectedChainId != 97) {
      const zeroContract = new web3.eth.Contract(
        ZERO_ABI,
        ZEROLAYER_ENDPOINT_LIST[selectedChainId]
      );
      const _adapterParams = ethers.utils.solidityPack(
        ["uint16", "uint"],
        [2, 600000]
      );
      const additionalValue = await zeroContract.methods
        .estimateFees(
          ZEROLAYER_CHAINID_LIST[97],
          TRADING_ADDRESS_LIST[97],
          "0x",
          false,
          _adapterParams
        )
        .call({ from: account });
      value = getBigNumber(additionalValue[0]);
      value = value.mul(1100).div(1000);
    } else {
      value = 0;
    }

    let gas = await tradingContract.methods
      .updateSl(
        pairIndex,
        _index,
        bsl,
        "0x0000000000000000000000000000000000000000"
      )
      .estimateGas({
        from: account,
        value: value.toString(),
      });
    const rtSl = tradingContract.methods
      .updateSl(
        pairIndex,
        _index,
        bsl,
        "0x0000000000000000000000000000000000000000"
      )
      .send({
        from: account,
        gas,
        value: value.toString(),
      });
    gas = await tradingContract.methods
      .updateTp(
        pairIndex,
        _index,
        btp,
        "0x0000000000000000000000000000000000000000"
      )
      .estimateGas({
        from: account,
        value: value.toString(),
      });
    const rtTp = tradingContract.methods
      .updateTp(
        pairIndex,
        _index,
        btp,
        "0x0000000000000000000000000000000000000000"
      )
      .send({
        from: account,
        gas,
        value: value.toString(),
      });
    toast.promise(Promise.all([rtSl, rtTp]), {
      pending: "Updating TP/SL.",
      success: {
        render() {
          return "Completed to update TP/SL.";
        },
        icon: ({ theme, type }) => (
          <img src="/assets/image/icon/ic-success.svg" />
        ),
      },
      error: {
        render() {
          return "Rejected to update.";
        },
        icon: ({ theme, type }) => (
          <img src="/assets/image/icon/ic-error.svg" />
        ),
      },
    });

    await rtSl;
    await rtTp;
    const mining = () =>
      new Promise((resolve) =>
        setTimeout(
          () => {
            setActions(actions + 1);
            resolve();
          },
          selectedChainId == 97 ? 5000 : 45000
        )
      );
    toast.promise(mining, {
      pending: "Update positions.",
      success: {
        render() {
          return "Completed to update.";
        },
        icon: ({ theme, type }) => (
          <img src="/assets/image/icon/ic-success.svg" />
        ),
      },
      error: {
        render() {
          return "Rejected mining";
        },
        icon: ({ theme, type }) => (
          <img src="/assets/image/icon/ic-error.svg" />
        ),
      },
    });
  }
  function getToken(address) {
    for (let i = 0; i < tokenMenu2.length; i++) {
      if (
        TOKENCONVERTOR[tokenMenu2[i].tideaddress][
          selectedChainId
        ].toLowerCase() == address.toLowerCase()
      ) {
        return tokenMenu2[i];
      }
    }
  }
  async function cancelLimitOrder(orderId) {
    toast.info("Started to cancel limit order.", {
      icon: ({ theme, type }) => <img src="/assets/image/icon/ic-info.svg" />,
    });
    const web3 = new Web3(
      library.library || chainData[this.props.selectedChainId].rpcUrls[0]
    );
    const limitContract = new web3.eth.Contract(
      LIMIT_ABI,
      LIMIT_ADDRESS_LIST[selectedChainId]
    );
    try {
      const gas = await limitContract.methods.cancelOrder(orderId).estimateGas({
        from: account,
      });
      const rt = limitContract.methods.cancelOrder(orderId).send({
        gas: gas,
        from: account,
      });
      await toast.promise(rt, {
        pending: "Cancelling limit order.",
        success: {
          render() {
            return "Completed to cancel limit order.";
          },
          icon: ({ theme, type }) => (
            <img src="/assets/image/icon/ic-success.svg" />
          ),
        },
        error: {
          render() {
            return "Rejected to cancel to limit.";
          },
          icon: ({ theme, type }) => (
            <img src="/assets/image/icon/ic-error.svg" />
          ),
        },
      });
      setTimeout(() => setActions(actions + 1), 3000);
    } catch (error) {
      if (error.__proto__.name === "Error") {
        const start = error.message.indexOf("{");
        const end = error.message.indexOf("}");
        if (start >= 0 && end >= 0) {
          error = JSON.parse(error.message.substring(start, end + 1));
          toast.error(error.message, {
            icon: ({ theme, type }) => (
              <img src="/assets/image/icon/ic-error.svg" />
            ),
          });
        }
      }
      console.log({ error });
    }
  }
  async function closePosition(_pairIndex, _index) {
    toast.info("Started to close position", {
      icon: ({ theme, type }) => <img src="/assets/image/icon/ic-info.svg" />,
    });
    const web3 = new Web3(
      library.provider || chainData[this.props.selectedChainId].rpcUrls[0]
    );
    const tradingContract = new web3.eth.Contract(
      TRADING_ABI,
      TRADING_ADDRESS_LIST[selectedChainId]
    );
    let value;
    if (selectedChainId != 97) {
      const zeroContract = new web3.eth.Contract(
        ZERO_ABI,
        ZEROLAYER_ENDPOINT_LIST[selectedChainId]
      );
      const _adapterParams = ethers.utils.solidityPack(
        ["uint16", "uint"],
        [1, 600000]
      );
      const additionalValue = await zeroContract.methods
        .estimateFees(
          ZEROLAYER_CHAINID_LIST[97],
          TRADING_ADDRESS_LIST[97],
          "0x",
          false,
          _adapterParams
        )
        .call({ from: account });
      value = getBigNumber(additionalValue[0]);
      value = value.mul(1100).div(1000);
    } else {
      value = 0;
    }

    const web3bsc = new Web3(BSCRPCURL);
    const storageContract = new web3bsc.eth.Contract(
      STORAGE_ABI,
      STORAGE_ADDRESS
    );
    const opentrades = await storageContract.methods
      .openTradesCount(account, pairIndex)
      .call();
    const opemlimits = await storageContract.methods
      .openLimitOrdersCount(account, pairIndex)
      .call();
    try {
      const gas = await tradingContract.methods
        .closeTradeByUser(
          _pairIndex,
          _index,
          slippageP,
          "0x0000000000000000000000000000000000000000"
        )
        .estimateGas({
          from: account,
          value: value.toString(),
        });
      const rt = tradingContract.methods
        .closeTradeByUser(
          _pairIndex,
          _index,
          slippageP,
          "0x0000000000000000000000000000000000000000"
        )
        .send({
          gas,
          from: account,
          value: value.toString(),
        });

      const transaction = await toast.promise(rt, {
        pending: "Closing trade.",
        success: {
          render() {
            return "Sent transaction.";
          },
          icon: ({ theme, type }) => (
            <img src="/assets/image/icon/ic-success.svg" />
          ),
        },
        error: {
          render() {
            return "Rejected to close trade.";
          },
          icon: ({ theme, type }) => (
            <img src="/assets/image/icon/ic-error.svg" />
          ),
        },
      });
      const mining = () =>
        new Promise((resolve) => {
          const check = async () => {
            console.log("call checking position");
            const opentradesafter = await storageContract.methods
              .openTradesCount(account, pairIndex)
              .call();
            const opemlimitsafter = await storageContract.methods
              .openLimitOrdersCount(account, pairIndex)
              .call();
            if (
              Number(opentradesafter) != Number(opentrades) ||
              Number(opemlimitsafter) != Number(opemlimits)
            ) {
              setActions(actions + 1);
              setTimeout(resolve, 5000);
            } else {
              setTimeout(check, 10000);
            }
          };
          setTimeout(check, selectedChainId == 97 ? 5000 : 40000);
        });
      toast.promise(mining, {
        pending: "Update positions.",
        success: {
          render() {
            return "Completed to update.";
          },
          icon: ({ theme, type }) => (
            <img src="/assets/image/icon/ic-success.svg" />
          ),
        },
        error: {
          render() {
            return "Faild to update.";
          },
          icon: ({ theme, type }) => (
            <img src="/assets/image/icon/ic-error.svg" />
          ),
        },
      });
    } catch (error) {
      if (error.__proto__.name === "Error") {
        const start = error.message.indexOf("{");
        const end = error.message.indexOf("}");
        if (start >= 0 && end >= 0) {
          error = JSON.parse(error.message.substring(start, end + 1));
          toast(error.message, {
            icon: ({ theme, type }) => (
              <img src="/assets/image/icon/ic-error.svg" />
            ),
          });
        }
      }
      console.log({ error });
    }
  }
  async function cancelOrder(_pairIndex, _index) {
    toast.info("Started to cancel order.", {
      icon: ({ theme, type }) => <img src="/assets/image/icon/ic-info.svg" />,
    });
    const web3 = new Web3(
      library.provider || chainData[this.props.selectedChainId].rpcUrls[0]
    );
    const tradingContract = new web3.eth.Contract(
      TRADING_ABI,
      TRADING_ADDRESS_LIST[selectedChainId]
    );
    let value;
    if (selectedChainId != 97) {
      const zeroContract = new web3.eth.Contract(
        ZERO_ABI,
        ZEROLAYER_ENDPOINT_LIST[selectedChainId]
      );
      const _adapterParams = ethers.utils.solidityPack(
        ["uint16", "uint"],
        [2, 600000]
      );
      const additionalValue = await zeroContract.methods
        .estimateFees(
          ZEROLAYER_CHAINID_LIST[97],
          TRADING_ADDRESS_LIST[97],
          "0x",
          false,
          _adapterParams
        )
        .call({ from: account });
      value = getBigNumber(additionalValue[0]);
      value = value.mul(1100).div(1000);
    } else {
      value = 0;
    }
    const web3bsc = new Web3(BSCRPCURL);
    const storageContract = new web3bsc.eth.Contract(
      STORAGE_ABI,
      STORAGE_ADDRESS
    );
    const opentrades = await storageContract.methods
      .openTradesCount(account, pairIndex)
      .call();
    const opemlimits = await storageContract.methods
      .openLimitOrdersCount(account, pairIndex)
      .call();

    try {
      const gas = await tradingContract.methods
        .cancelOrder(
          _pairIndex,
          _index,
          "0x0000000000000000000000000000000000000000"
        )
        .estimateGas({
          from: account,
          value: value.toString(),
        });
      const rt = tradingContract.methods
        .cancelOrder(
          _pairIndex,
          _index,
          "0x0000000000000000000000000000000000000000"
        )
        .send({
          gas,
          from: account,
          value: value.toString(),
        });
      await toast.promise(rt, {
        pending: "Cancelling order.",
        success: {
          render() {
            return "Sent transaction.";
          },
          icon: ({ theme, type }) => (
            <img src="/assets/image/icon/ic-success.svg" />
          ),
        },
        error: {
          render() {
            return "Rejected to cancel.";
          },
          icon: ({ theme, type }) => (
            <img src="/assets/image/icon/ic-error.svg" />
          ),
        },
      });
      const mining = () =>
        new Promise((resolve) => {
          const check = async () => {
            const opentradesafter = await storageContract.methods
              .openTradesCount(account, pairIndex)
              .call();
            const opemlimitsafter = await storageContract.methods
              .openLimitOrdersCount(account, pairIndex)
              .call();
            if (
              Number(opentradesafter) != Number(opentrades) ||
              Number(opemlimitsafter) != Number(opemlimits)
            ) {
              setActions(actions + 1);
              setTimeout(resolve, 5000);
            } else {
              setTimeout(check, 10000);
            }
          };
          setTimeout(check, selectedChainId == 97 ? 5000 : 40000);
        });
      toast.promise(mining, {
        pending: "Update positions.",
        success: {
          render() {
            return "Completed to update.";
          },
          icon: ({ theme, type }) => (
            <img src="/assets/image/icon/ic-success.svg" />
          ),
        },
        error: {
          render() {
            return "Failed to update";
          },
          icon: ({ theme, type }) => (
            <img src="/assets/image/icon/ic-error.svg" />
          ),
        },
      });
    } catch (error) {
      if (error.__proto__.name === "Error") {
        const start = error.message.indexOf("{");
        const end = error.message.indexOf("}");
        if (start >= 0 && end >= 0) {
          error = JSON.parse(error.message.substring(start, end + 1));
          toast.error(error.message, {
            icon: ({ theme, type }) => (
              <img src="/assets/image/icon/ic-error.svg" />
            ),
          });
        }
      }
      console.log({ error });
    }
  }
  function profit(buy, positionInfo, openPrice) {
    const amount = buy
      ? formatDecimals(positionInfo.positionAmount, USDC_PRECISION)
      : formatDecimals(positionInfo.borrowAmount, USDC_PRECISION);
    const profitAll =
      Number(amount) *
      (buy
        ? Number(price) - Number(openPrice)
        : -Number(price) + Number(openPrice));
    return profitAll.toFixed(2);
  }
  function profit_percent(buy, positionInfo, openPrice) {
    const amount = buy
      ? formatDecimals(positionInfo.positionAmount, USDC_PRECISION)
      : formatDecimals(positionInfo.borrowAmount, USDC_PRECISION);
    const profitAll =
      Number(amount) *
      (buy
        ? Number(price) / Number(openPrice) - 1
        : Number(openPrice) / Number(price) - 1);
    return (profitAll * 100).toFixed(2);
  }

  function changeTpSl(id) {
    setTp(tradeInfo[pairIndex]?.positions[id].tp);
    setSl(tradeInfo[pairIndex]?.positions[id].sl);
    setIndex(id);
    setOpenModal(true);
  }

  const [sideFilterPop, setSideFilterPop] = useState(null);
  const [pairIndexFilterPop, setPairIndexFilterPop] = useState(null);
  const [positionPairIndexFilterPop, setPositionPairIndexFilterPop] =
    useState(pairIndex);
  const [pairList, setPairList] = useState([]);
  useEffect(() => {
    let pairList = tokens.map((token) => {
      return {
        value: token.pairIndex,
        item: token.pair,
      };
    });
    pairList.unshift({
      value: "-1",
      item: "All Pairs",
    });
    console.log({ pairList, tokens });
    setPairList(pairList);
  }, [tokens]);

  const renderRow = (position, i) => {
    const types = {
      true: { label: "Long", color: "text-primary" },
      false: { label: "Short", color: "text-warning" },
    };
    if (tokens.length == 0) return <></>;
    return (
      <tr className="border-t border-t-border-main last:border-b last:border-b-border-main">
        <td className="py-5 px-5 relative text-sm leading-4">
          <img src={SymbolIcon} className="absolute top-0 left-0" />
          <div className="flex items-center">
            <img
              src={
                tokens.length > 0
                  ? `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${ethers.utils.getAddress(
                      tokens[position.pairIndex].address
                    )}/logo.png`
                  : ""
              }
              className="w-10 h-10"
            />
            <div className="ml-3 flex flex-col">
              <span className="uppercase mb-[5px]">
                {tokens[position.pairIndex].symbol}/USD
              </span>
              <span
                className={`${
                  position.buy ? "text-success" : "text-danger"
                } text-xs`}
              >
                {types[position.buy].label} {position.leverage}X
              </span>
            </div>
          </div>
        </td>
        <td className="py-5 text-sm">
          {position.buy
            ? numberFormat(
                formatDecimals(
                  tradeInfo[pairIndex]?.positionInfos[i].positionAmount,
                  USDC_PRECISION
                )
              )
            : numberFormat(
                formatDecimals(
                  tradeInfo[pairIndex]?.positionInfos[i].borrowAmount,
                  USDC_PRECISION
                )
              )}
          {tokens[position.pairIndex].symbol}
        </td>
        <td className="py-5 text-sm">
          {numberFormat(
            formatDecimals(position.positionSizeDai, USDC_PRECISION)
          )}
        </td>
        <td className="py-5 text-sm">
          ${numberFormat(formatDecimals(position.openPrice, USDC_PRECISION))}
        </td>
        <td className="py-5 text-sm">${numberFormat(price, bscdecimals)}</td>
        <td className="py-5 text-sm">
          {numberFormat(
            formatDecimals(
              tradeInfo[pairIndex]?.positionInfos[i].liq,
              USDC_PRECISION
            ),
            tokens[position.pairIndex].bscdecimals
          )}
        </td>
        <td className="py-5 text-xs">
          <div className="flex flex-col">
            <span
              className={`${
                profit(
                  position.buy,
                  tradeInfo[pairIndex]?.positionInfos[i],
                  Number(position.openPrice) / Math.pow(10, USDC_PRECISION)
                ) > 0
                  ? `text-success`
                  : `text-danger`
              } mb-[5px]`}
            >
              {profit(
                position.buy,
                tradeInfo[pairIndex]?.positionInfos[i],
                Number(position.openPrice) / Math.pow(10, USDC_PRECISION)
              ) < 0
                ? `-$${profit(
                    position.buy,
                    tradeInfo[pairIndex]?.positionInfos[i],
                    Number(position.openPrice) / Math.pow(10, USDC_PRECISION)
                  )
                    .toString()
                    .slice(1)}`
                : `$${profit(
                    position.buy,
                    tradeInfo[pairIndex]?.positionInfos[i],
                    Number(position.openPrice) / Math.pow(10, USDC_PRECISION)
                  )}`}
            </span>
            <span className="opacity-50">
              {profit_percent(
                position.buy,
                tradeInfo[pairIndex]?.positionInfos[i],
                Number(position.openPrice) / Math.pow(10, USDC_PRECISION)
              )}
              %
            </span>
          </div>
        </td>
        <td className="py-5">
          <button
            className="text-xs h-8 px-5 bg-[#121212] rounded"
            onClick={() => {
              closePosition(position.pairIndex, position.index);
            }}
          >
            Close
          </button>
        </td>
      </tr>
    );
  };
  return (
    <div>
      <div className="w-full h-[54px] flex items-center justify-between bg-[#0F0F0F] border-y border-y-border-main md:px-5">
        <ul className="w-full h-full flex md:gap-10">
          {tabs.map((tab) => (
            <li
              key={tab.id}
              className={`w-full md:w-auto flex items-center justify-center text-sm cursor-pointer border-b ${
                selectedTab === tab.id
                  ? `text-primary border-b-primary`
                  : `text-white border-b-transparent`
              }`}
              onClick={() => setSelectedTab(tab.id)}
            >
              {tab.label}
            </li>
          ))}
        </ul>
        <button className="hidden md:flex flex-shrink-0 items-center gap-[5px] bg-light-primary px-2.5 py-[5px] rounded-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
          >
            <g clipPath="url(#clip0_562_58)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.4898 14.2369C11.6911 14.3794 11.9504 14.415 12.1817 14.3275C12.4129 14.2394 12.5829 14.0419 12.6342 13.8025C13.1773 11.25 14.4948 4.78938 14.9892 2.4675C15.0267 2.2925 14.9642 2.11063 14.8267 1.99375C14.6892 1.87688 14.4986 1.84313 14.3286 1.90625C11.7079 2.87625 3.63732 5.90438 0.338571 7.125C0.129196 7.2025 -0.00705359 7.40375 -0.000178588 7.62438C0.00732141 7.84563 0.156071 8.0375 0.370446 8.10188C1.84982 8.54438 3.7917 9.16 3.7917 9.16C3.7917 9.16 4.6992 11.9006 5.17232 13.2944C5.2317 13.4694 5.36857 13.6069 5.5492 13.6544C5.7292 13.7013 5.9217 13.6519 6.05607 13.525C6.81607 12.8075 7.99107 11.6981 7.99107 11.6981C7.99107 11.6981 10.2236 13.335 11.4898 14.2369ZM4.60857 8.81375L5.65795 12.275L5.89107 10.0831C5.89107 10.0831 9.94545 6.42625 12.2567 4.34188C12.3242 4.28063 12.3336 4.17813 12.2773 4.10625C12.2217 4.03438 12.1192 4.0175 12.0423 4.06625C9.36357 5.77688 4.60857 8.81375 4.60857 8.81375Z"
                fill="#F3BA2F"
              />
            </g>
            <defs>
              <clipPath id="clip0_562_58">
                <rect width="15" height="15" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span className="text-xs text-primary leading-[15px]">
            Need help?
          </span>
        </button>
      </div>
      <div className="block lg:hidden py-5">
        {tokens.length > 0 &&
          tradeInfo[pairIndex]?.positions.map((position, i) => (
            <div className="px-5 mb-5" key={i}>
              <PositionCard
                leverage={position.leverage}
                profit={profit(
                  position.buy,
                  tradeInfo[pairIndex]?.positionInfos[i],
                  Number(position.openPrice) / Math.pow(10, USDC_PRECISION)
                )}
                profit_percent={profit_percent(
                  position.buy,
                  tradeInfo[pairIndex]?.positionInfos[i],
                  Number(position.openPrice) / Math.pow(10, USDC_PRECISION)
                )}
                position={position}
                symbol={tokens[position.pairIndex].symbol}
                tokens={tokens}
                i={i}
                onClose={() => {
                  closePosition(position.pairIndex, position.index);
                }}
                positionSize={
                  position.buy
                    ? numberFormat(
                        formatDecimals(
                          tradeInfo[pairIndex]?.positionInfos[i].positionAmount,
                          USDC_PRECISION
                        )
                      )
                    : numberFormat(
                        formatDecimals(
                          tradeInfo[pairIndex]?.positionInfos[i].borrowAmount,
                          USDC_PRECISION
                        )
                      )
                }
                entryprice={`${numberFormat(
                  formatDecimals(position.openPrice, USDC_PRECISION)
                )}`}
                price={numberFormat(price, bscdecimals)}
                positionSizeDai={numberFormat(
                  formatDecimals(position.positionSizeDai, USDC_PRECISION)
                )}
                liq={numberFormat(
                  formatDecimals(
                    tradeInfo[pairIndex]?.positionInfos[i].liq,
                    USDC_PRECISION
                  ),
                  tokens[position.pairIndex].bscdecimals
                )}
                tokenimage={`https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${ethers.utils.getAddress(
                  tokens[position.pairIndex].address
                )}/logo.png`}
              />
            </div>
          ))}
      </div>
      <div className="hidden lg:block min-h-[320px]">
        <DataTable
          cols={cols}
          rows={tradeInfo[pairIndex]?.positions}
          renderRow={renderRow}
        />
      </div>
      <ClosePositionModal open={opened} onClose={handleToggleOpen} />
    </div>
  );
};

export default TradingPositions;
