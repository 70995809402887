import React, { useState } from "react";
import SelectTokenModal from "components/Modal/SelectToken";
import CaretIcon from "assets/images/icons/caret.svg";

import { numberFormat } from "../../utils";

const TradingOverview = ({
  aniOldPrice,
  aniCurPrice,
  bscdecimals,
  handleTradingview,
  fromSymbol,
  fromAddress,
  pairName,
  tokens,
  handleToken,
  extraListInfo,
  changeLoaded,
  close,
  high,
  low,
  change,
  change_amount,
  volume,
  volume_quote,
  upprice,
  pairIndex,
}) => {
  const [opened, setOpened] = useState(false);
  const handleToggleOpen = () => setOpened(!opened);

  const [anchorPairPopover, setAnchorPairPopover] = useState(null);

  const handlePairPopoverClick = (event) => {
    setAnchorPairPopover(event.currentTarget);
  };

  const handlePairPopoverClose = () => {
    setAnchorPairPopover(null);
  };

  const pairPopoverOpen = Boolean(anchorPairPopover);
  const pairPopoverId = pairPopoverOpen ? "pair-popover" : undefined;

  return (
    <div className="h-[60px] bg-[#0F0F0F] border-b border-b-border-main flex">
      <div
        className="flex items-center px-5 gap-2.5 cursor-pointer border-r border-r-border-main"
        onClick={handleToggleOpen}
      >
        <span className="text-sm lg:text-base uppercase">{fromSymbol}/USD</span>
        <img src={CaretIcon} alt="" />
      </div>
      <div className="flex items-center relative px-10 md:px-[35px] gap-[30px]">
        <div className="relative flex flex-col justify-center gap-[5px]">
          <span className="hidden md:inline text-[13px] leading-4 opacity-50">
            Price
          </span>
          <span className="text-lg md:text-sm md:leading-[17px] font-bold md:font-normal">
            ${changeLoaded ? numberFormat(aniCurPrice, bscdecimals) : "-"}
            <span className="inline-block md:hidden font-normal text-xs leading-[17px] text-danger ml-2.5">
              ({changeLoaded ? Number(change).toFixed(2) : "-"})%
            </span>
          </span>
        </div>
        <div className="relative hidden lg:flex flex-col justify-center gap-[5px]">
          <span className="text-[13px] leading-4 opacity-50">Change</span>
          <span
            className={`text-sm leading-[17px] ${
              changeLoaded && Number(change) > 0
                ? "text-success"
                : "text-danger"
            }`}
          >
            {changeLoaded ? Number(change).toFixed(2) : "-"}%
          </span>
        </div>
        <div className="relative hidden lg:flex flex-col justify-center gap-[5px]">
          <span className="text-[13px] leading-4 opacity-50">
            24H High / Low
          </span>
          <span className="text-sm leading-[17px]">
            ${changeLoaded ? numberFormat(high, bscdecimals) : "-"} / $
            {changeLoaded ? numberFormat(low, bscdecimals) : "-"}
          </span>
        </div>
      </div>

      <SelectTokenModal
        open={opened}
        onClose={handleToggleOpen}
        handleToken={handleToken}
        extraListInfo={extraListInfo}
        tokens={tokens}
      />
    </div>
  );
};

export default TradingOverview;
