import React from "react";

const FavoriteTokens = ({ tokens, handleToken }) => {
  const favoriteTokens = [
    { id: 1, label: "BTC" },
    { id: 2, label: "ETH" },
    { id: 3, label: "BNB" },
  ];

  const handleClick = (symbol) => {
    const token = getTokenInfoBySymbol(symbol);
    handleToken(token.address, token.pair, token.symbol, token.pairIndex);
  };

  const getTokenInfoBySymbol = (symbol) => {
    return tokens.filter((token) => token.symbol === symbol)[0];
  };

  return (
    <div className="flex items-center gap-[5px] h-11 border-b border-b-border-main px-5">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
      >
        <g clipPath="url(#clip0_511_8)">
          <g mask="url(#mask0_511_8)">
            <path
              d="M7.5 11.8313L12.135 14.5666L10.9088 9.4113L15 5.9463L9.6075 5.49163L7.5 0.633301L5.3925 5.49163L0 5.9463L4.09125 9.4113L2.865 14.5666L7.5 11.8313Z"
              fill="#FFBF17"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_511_8">
            <rect
              width="15"
              height="14.6667"
              fill="white"
              transform="translate(0 0.266663)"
            />
          </clipPath>
        </defs>
      </svg>
      <div className="flex items-center">
        {favoriteTokens.map((token) => (
          <button
            key={token.id}
            className="flex items-center gap-1 text-[13px] leading-4 p-2.5 rounded-[5px] bg-transparent hover:bg-[#121212] transition"
            onClick={() => handleClick(token.label)}
          >
            <span>{token.label}/USD</span>
            {token.closed && <span className="text-danger">CLOSED</span>}
          </button>
        ))}
      </div>
    </div>
  );
};

export default FavoriteTokens;
