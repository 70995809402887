import React, { useState } from "react";
import ConnectWalletModal from "components/Modal/ConnectWallet";
import Tab from "./Tab";
import TokenSelector from "./TokenSelector";
import Button from "components/Button";
import LockIcon from "assets/images/icons/lock.svg";
import Summary from "./Summary";
import SelectLeverage from "./SelectLeverage";
import CaretIcon from "assets/images/icons/caret.svg";
import OutsideClickHandler from "react-outside-click-handler";
import { numberFormat } from "../../../utils";
import { useWeb3React } from "@web3-react/core";

const TradingControl = ({
  bscdecimals,
  handleSwapOptions,
  swapOption,
  handleSwapType,
  swapType,
  handleTokenMenu1,
  handleTokenMenu2,
  setMaxFrom,
  confirmTrade,
  handleSlippage,
  slippage,
  handleOpenPrice,
  openPrice,
  fromAmount,
  handleFromAmount,
  fromSymbol,
  handleToAmount,
  toAmount,
  toSymbol,
  handleSlider,
  slider,
  setOpenModal,
  activeWallet,
  price,
  entryprice,
  pairInterest,
  pairBorrow,
  futureBalace,
  pairIndex,
  tokens,
  handleToken,
  extraListInfo,
  open,
  onClose,
}) => {
  const [openedConnectWallet, setOpenedConnectWallet] = useState(false);
  const [openedOrderType, setOpenedOrderType] = useState(false);
  const [payToken, setPayToken] = useState("usdt");
  const [longToken, setLongToken] = useState("btc");

  const tabs = [
    { id: 0, label: "Buy / Long" },
    { id: 1, label: "Sell / Short" },
  ];
  const orderTypes = [
    { id: 0, label: "Market" },
    { id: 1, label: "Limit" },
  ];
  const handleToggleOpen = () => setOpenedConnectWallet(!openedConnectWallet);
  const handleToggleOpenOrderType = () => setOpenedOrderType(!openedOrderType);
  const handleClickOrderType = () => {};

  return (
    <div
      className={`${
        open ? `flex` : `hidden`
      } md:flex w-full items-end md:items-start h-screen md:h-auto md:max-w-[350px] border-l border-l-border-main fixed md:relative left-0 md:left-auto bottom-0 md:bottom-auto z-50`}
    >
      <div
        className="fixed block md:hidden top-0 left-0 bottom-0 right-0 bg-dark opacity-70"
        onClick={onClose}
      />
      <div className="h-auto md:h-full relative bg-[#080808] w-full border-t border-t-border-main md:border-t-0">
        <div className="flex items-center justify-end md:hidden  bg-black border-b border-b-border-main">
          <button
            className="p-[15px] border-l border-l-border-main"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 21"
              fill="none"
            >
              <g clipPath="url(#clip0_591_1842)">
                <path
                  d="M4.16634 5.84567L5.34551 4.6665L9.99967 9.32067L14.6538 4.6665L15.833 5.84567L11.1788 10.4998L15.833 15.154L14.6538 16.3332L9.99967 11.679L5.34551 16.3332L4.16634 15.154L8.82051 10.4998L4.16634 5.84567Z"
                  fill="#F2F2F2"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_591_1842">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="matrix(-1 0 0 1 20 0.5)"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
        <Tab tabs={tabs} active={swapOption} onChange={handleSwapOptions} />
        <div className="p-[15px] flex flex-col gap-[25px]">
          <div className="flex flex-col gap-[5px]">
            <div className="grid grid-cols-2 gap-[5px]">
              <div className="flex flex-col gap-[7.5px] bg-[#0F0F0F] border border-[#151515] rounded-[5px] px-[15px] py-[11px]">
                <p className="flex items-center gap-[5px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7"
                    height="10"
                    viewBox="0 0 7 10"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_527_2)">
                      <path
                        d="M5.50505 3.7625V2.9375C5.50505 1.7825 4.62276 0.875 3.49984 0.875C2.37692 0.875 1.49463 1.7825 1.49463 2.9375V3.7625C0.81286 3.7625 0.291504 4.29875 0.291504 5V7.8875C0.291504 8.58875 0.81286 9.125 1.49463 9.125H5.50505C6.1868 9.125 6.70815 8.58875 6.70815 7.8875V5C6.70815 4.29875 6.1868 3.7625 5.50505 3.7625ZM2.29671 2.9375C2.29671 2.23625 2.81806 1.7 3.49984 1.7C4.18161 1.7 4.70296 2.23625 4.70296 2.9375V3.7625H2.29671V2.9375ZM3.90088 7.0625C3.90088 7.31 3.74046 7.475 3.49984 7.475C3.25921 7.475 3.09879 7.31 3.09879 7.0625V5.825C3.09879 5.5775 3.25921 5.4125 3.49984 5.4125C3.74046 5.4125 3.90088 5.5775 3.90088 5.825V7.0625Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_527_2">
                        <rect
                          width="7"
                          height="9"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <span className="text-[13px] opacity-50">
                    {swapType == 0 ? "Market Price" : "Limit Price"}
                  </span>
                </p>
                <p className="font-medium opacity-50 text-sm">
                  $
                  {swapType == 0 ? numberFormat(price, bscdecimals) : openPrice}
                </p>
              </div>
              <div className="flex flex-col items-end gap-[7.5px] bg-[#0F0F0F] border border-[#151515] rounded-[5px] px-[15px] py-[11px]">
                <p className="text-[13px] opacity-50">Order Type</p>
                <div className="relative">
                  <div
                    className="flex items-center gap-[5px] cursor-pointer"
                    onClick={handleToggleOpenOrderType}
                  >
                    <span className="font-medium text-sm">
                      {swapType == 0 ? "Market" : "Limit"}
                    </span>
                    <img src={CaretIcon} />
                  </div>
                  {openedOrderType && (
                    <OutsideClickHandler
                      onOutsideClick={() => setOpenedOrderType(false)}
                    >
                      <ul className="z-50 absolute right-0 -bottom-2 translate-y-full w-[164px] bg-[#252525] border border-[#383838] p-[15px] flex flex-col gap-[15px] text-xs cursor-pointer">
                        {orderTypes.map((order, i) => {
                          return (
                            <li
                              key={i}
                              className="text-right transition hover:opacity-50"
                              onClick={() => {
                                handleSwapType(order.id);
                                handleToggleOpenOrderType();
                              }}
                            >
                              {order.label}
                            </li>
                          );
                        })}
                      </ul>
                    </OutsideClickHandler>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-[7.5px] bg-[#0F0F0F] border border-[#151515] rounded-[5px] px-[15px] py-[11px]">
              <div className="flex items-center justify-between text-[13px] opacity-50">
                <span>Pay</span>
                <span>
                  Balance : {activeWallet ? numberFormat(futureBalace) : "-"}
                </span>
              </div>
              <div className="flex justify-between items-center">
                <input
                  className="w-40 flex-grow h-3.5 outline-none border-0 bg-transparent font-medium text-sm p-0"
                  type="text"
                  value={fromAmount}
                  onChange={handleFromAmount}
                />
                <div className="flex items-center gap-[5px] cursor-pointer hover:opacity-60 transition">
                  <img
                    src={
                      tokens.length > 0
                        ? `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/0x55d398326f99059fF775485246999027B3197955/logo.png`
                        : ""
                    }
                    className="w-[15px] h-[15px] rounded-full"
                    alt=""
                  />
                  <span className="font-medium text-sm">USDT</span>
                  <img src={CaretIcon} alt="" />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-[7.5px] bg-[#0F0F0F] border border-[#151515] rounded-[5px] px-[15px] py-[11px]">
              <div className="flex items-center justify-between text-[13px] opacity-50">
                <span>Long</span>
              </div>
              <div className="flex justify-between">
                <input
                  className="w-40 flex-grow h-3.5 outline-none border-0 bg-transparent font-medium text-sm p-0"
                  type="text"
                  value={toAmount}
                  disabled={true}
                  onChange={handleToAmount}
                />

                <TokenSelector
                  symbol={fromSymbol}
                  pairIndex={pairIndex}
                  tokens={tokens}
                  handleToken={handleToken}
                  extraListInfo={extraListInfo}
                />
              </div>
            </div>
          </div>
          <SelectLeverage handleSlider={handleSlider} slider={slider} />
          <div className="flex flex-col gap-[15px]">
            <Button
              size="lg"
              className="w-full"
              onClick={!activeWallet ? handleToggleOpen : confirmTrade}
            >
              {!activeWallet ? "Connect wallet" : "Open position"}
            </Button>
            <Summary
              pairInterest={pairInterest}
              pairBorrow={numberFormat(pairBorrow)}
              liqprice={
                swapOption == 0
                  ? ((1 - 1 / Number(slider)) * Number(entryprice)).toFixed(
                      bscdecimals
                    )
                  : ((1 + 1 / Number(slider)) * Number(entryprice)).toFixed(
                      bscdecimals
                    )
              }
            />
          </div>
        </div>
      </div>
      <ConnectWalletModal
        open={openedConnectWallet}
        onClose={handleToggleOpen}
      />
    </div>
  );
};

export default TradingControl;
