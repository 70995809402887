import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ChooseNetwork from "./ChooseNetwork.jsx";
import Wallet from "./Wallet.jsx";
import ConnectWalletModal from "components/Modal/ConnectWallet";
import Button from "components/Button";
import { useWeb3React } from "@web3-react/core";
import StarIcon from "assets/images/icons/star.svg";
import BNBIcon from "assets/images/icons/bnb_test.svg";
import ARBITRUMIcon from "assets/images/icons/arbitrum_test.svg";
import OPTIMISMIcon from "assets/images/icons/optimism_test.svg";
import FUJIIcon from "assets/images/icons/fuji_test.svg";
import BASEIcon from "assets/images/icons/base_test.svg";

const Header = ({ setLibrary, setActive, setSelectedChainId }) => {
  const { active, account, chainId } = useWeb3React();
  useEffect(() => {
    setActive(active && account);
  }, [active, account]);
  useEffect(() => {
    setSelectedChainId(chainId);
  }, [chainId]);
  const location = useLocation();
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);
  const routes = [
    { id: 1, label: "Trade", path: "/" },
    { id: 2, label: "Earn" },
    { id: 3, label: "Rewards" },
    { id: 4, label: "Stats" },
    { id: 5, label: "On/Off Ramp" },
    { id: 6, label: "Airdrop", active: true },
  ];
  const handleToggleOpen = () => setOpened(!opened);
  const handleClick = (path) => {
    path
      ? navigate(path)
      : window.open("https://tideexchange.gitbook.io/tide/", "_blank");
  };

  return (
    <>
      <div className="h-[52px] flex items-center justify-between px-[15px] md:px-5 py-2.5 border-b border-b-border-main">
        <div className="flex items-center gap-2.5 md:gap-[25px]">
          <span className="font-bold">LOGO</span>
          <ul className="hidden md:flex gap-[25px]">
            {routes.map((route) => {
              const active = location.pathname === route.path;
              return (
                <li
                  key={route.id}
                  className={`text-sm cursor-pointer transition relative hover:opacity-100 ${
                    route.active && "text-primary !opacity-100"
                  } ${active ? `opacity-100` : `opacity-50`}`}
                  onClick={() => handleClick(route.path)}
                >
                  {route.label}
                  {route.active && (
                    <img
                      src={StarIcon}
                      className="absolute top-0 right-0 translate-x-2.5"
                    />
                  )}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="flex items-center text-sm text-[#F2F2F2]">
          {!active ? (
            <div className="flex items-center gap-[15px]">
              <span className="w-[1px] h-[15px] hidden md:inline-block bg-[#191C29]" />
              <div className="hidden md:flex items-center gap-[15px]">
                <span>Supported Networks</span>
                <div className="flex items-center gap-[3px]">
                  <img src={BNBIcon} className="w-[17px]" />
                  <img src={ARBITRUMIcon} className="w-[17px]" />
                  <img src={OPTIMISMIcon} className="w-[17px]" />
                  <img src={FUJIIcon} className="w-[17px]" />
                  <img src={BASEIcon} className="w-[17px]" />
                </div>
              </div>
              <span className="w-[1px] h-[15px] inline-block bg-[#191C29]" />
              <Button onClick={handleToggleOpen}>Connect wallet</Button>
            </div>
          ) : (
            <div className="flex items-center gap-[15px]">
              <ChooseNetwork setLibrary={setLibrary} />
              <span className="w-[1px] h-[15px] inline-block bg-[#191C29]" />
              <Wallet />
            </div>
          )}
        </div>
      </div>
      <ConnectWalletModal open={opened} onClose={handleToggleOpen} />
    </>
  );
};

export default Header;
