import React, { useState } from "react";

const Alert = () => {
  const [show, setShow] = useState(true);
  const handleHide = () => setShow(false);

  return (
    <>
      {show && (
        <div className="bg-primary font-silka text-black flex items-center text-xs md:text-[13px] justify-center gap-4 md:gap-[25px] px-5 py-2.5">
          THE FUTURE OF BLOCKCHAIN IS LIMITLESS. TRADE ACROSS BLOCKCHAINS AND
          ACCESS LIQUIDITY WITHOUT BORDERS.
          <button onClick={handleHide}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="9"
              viewBox="0 0 9 9"
              fill="none"
            >
              <g clipPath="url(#clip0_510_3)">
                <path
                  d="M3.64228 4.35061L0.924607 1.33976C0.81312 1.22519 0.75058 1.07048 0.75058 0.909267C0.75058 0.748039 0.81312 0.593331 0.924607 0.478765C0.979353 0.422201 1.04461 0.377282 1.11658 0.346622C1.18854 0.31596 1.26579 0.300171 1.34381 0.300171C1.42183 0.300171 1.49907 0.31596 1.57104 0.346622C1.64301 0.377282 1.70827 0.422201 1.76301 0.478765L5.07683 3.91924C5.18816 4.03406 5.25059 4.18887 5.25059 4.35017C5.25059 4.51147 5.18816 4.66628 5.07683 4.78111L1.76301 8.22158C1.70827 8.27814 1.64301 8.32306 1.57104 8.35372C1.49907 8.38438 1.42183 8.40017 1.34381 8.40017C1.26579 8.40017 1.18854 8.38438 1.11658 8.35372C1.04461 8.32306 0.979353 8.27814 0.924607 8.22158C0.81312 8.10701 0.75058 7.95231 0.75058 7.79108C0.75058 7.62986 0.81312 7.47514 0.924607 7.36057L3.64228 4.35061Z"
                  fill="black"
                />
                <path
                  d="M5.35945 4.35061L8.07714 1.33976C8.18862 1.22519 8.25116 1.07048 8.25116 0.909267C8.25116 0.748039 8.18862 0.593331 8.07714 0.478765C8.02239 0.422201 7.95713 0.377282 7.88517 0.346622C7.81319 0.31596 7.73595 0.300171 7.65793 0.300171C7.5799 0.300171 7.50267 0.31596 7.4307 0.346622C7.35873 0.377282 7.29347 0.422201 7.23873 0.478765L3.9249 3.91924C3.81357 4.03406 3.75116 4.18887 3.75116 4.35017C3.75116 4.51147 3.81357 4.66628 3.9249 4.78111L7.23873 8.22158C7.29347 8.27814 7.35873 8.32306 7.4307 8.35372C7.50267 8.38438 7.5799 8.40017 7.65793 8.40017C7.73595 8.40017 7.81319 8.38438 7.88517 8.35372C7.95713 8.32306 8.02239 8.27814 8.07714 8.22158C8.18862 8.10701 8.25116 7.95231 8.25116 7.79108C8.25116 7.62986 8.18862 7.47514 8.07714 7.36057L5.35945 4.35061Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_510_3">
                  <rect width="9" height="9" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      )}
    </>
  );
};

export default Alert;
