import React, { useState } from "react";
import CaretIcon from "assets/images/icons/caret.svg";
import SelectTokenModal from "components/Modal/SelectToken";
import * as ethers from "ethers";
const TokenSelector = ({
  pairIndex,
  symbol,
  tokens,
  handleToken,
  extraListInfo,
}) => {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <div
        className="flex items-center gap-[5px] cursor-pointer hover:opacity-60 transition"
        onClick={() => setOpened(true)}
      >
        <img
          src={
            tokens.length > 0
              ? `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${ethers.utils.getAddress(
                  tokens[pairIndex].address
                )}/logo.png`
              : ""
          }
          className="w-[15px] h-[15px] rounded-full"
          alt=""
        />
        <span className="font-medium text-sm">{symbol}</span>
        <img src={CaretIcon} alt="" />
      </div>
      <SelectTokenModal
        open={opened}
        onClose={() => setOpened(false)}
        handleToken={handleToken}
        extraListInfo={extraListInfo}
        tokens={tokens}
      />
    </>
  );
};

export default TokenSelector;
