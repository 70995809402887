import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { symbols } from './symbols.js';
const intervals = {
    '1': '1m',
    '3': '3m',
    '5': '5m',
    '15': '15m',
    '30': '30m',
    '60': '1h',
    '120': '2h',
    '240': '4h',
    '360': '6h',
    '480': '8h',
    '720': '12h',
    'D': '1d',
    '1D': '1d',
    '3D': '3d',
    'W': '1w',
    '1W': '1w',
    'M': '1M',
    '1M': '1M',
}

const configurationData = {
    supported_resolutions: ['1','5','15','30', '60','1D', '1W', '1M']
};

function request(url, params = {}) {
    
    return axios({
        baseURL: 'https://back.tide.exchange/api/bsc',
        method: 'get',
        url,
        params
    })
    .then(res => res.data)
    .catch(res => {console.log(res);return undefined;})
}
const getLastBar = async(symbol,interval)=>{
    interval = intervals[interval] // set interval
    const res = await request('', { url:'klines',symbol: symbol.toUpperCase(), interval, limit:1 });

    return res
}
const getExchangeServerTime = () => request('',{url:'time'}).then(res => res?res.serverTime:undefined);
const getSymbols = () => {return symbols};
const resolveSymbol = async(symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {

        symbolName = symbolName.replace("USD","USDT")
        

        // need for pricescale()
        function pricescale(symbol) {
            for (let filter of symbol.filters) {
                if (filter.filterType == 'PRICE_FILTER') {
                    return Math.round(1 / parseFloat(filter.tickSize))
                }
            }
            return 1
        }

        const symbolInfo = (symbol) => ({
            name: symbol.symbol,
            description: symbol.baseAsset + ' /USD',
            ticker: symbol.symbol,
            //exchange: 'Binance',
            //listed_exchange: 'Binance',
            //type: 'crypto',
            session: '24x7',
            minmov: 1,
            pricescale: pricescale(symbol), //  or 100
            timezone: 'UTC',
            has_intraday: true,
            has_daily: true,
            has_weekly_and_monthly: true,
            currency_code: symbol.quoteAsset
        })

        // Get symbols
        const allsymbols = getSymbols()
            
        const symbol = allsymbols.find(i => i.symbol == symbolName)
            
        return symbol ? onSymbolResolvedCallback(symbolInfo(symbol)) : onResolveErrorCallback('[resolveSymbol]: symbol not found')

    }
const getKlines = ({ symbol, interval, from, to }) => {
    interval = intervals[interval] // set interval

    from *= 1000
    to *= 1000

    return request('', {url:'klines1', symbol: symbol.toUpperCase(), interval, startTime: from, endTime: to })
        .then(res => {
            return res?.map(i => ({
                time: parseFloat(i[0]),
                open: parseFloat(i[1]),
                high: parseFloat(i[2]),
                low: parseFloat(i[3]),
                close: parseFloat(i[4]),
                volume: parseFloat(i[5]),
            }))
        })
}
const checkInterval = (interval) => !!intervals[interval]
const getBars = async (symbolInfo, interval, periodParams, onHistoryCallback, onErrorCallback) => {
        
        if (!checkInterval(interval)) {
            console.log('[getBars] Invalid interval');
            return onErrorCallback('[getBars] Invalid interval')
        }

        const klines = await getKlines({ symbol: symbolInfo.name, interval, from: periodParams.from, to: periodParams.to })
        //console.log('call get bars',klines,from,to);
        if(klines?.length==0){
            return onHistoryCallback([], {
                        noData: true,
                    });

        }else{
            return onHistoryCallback(klines)

        }

        //onErrorCallback('Klines data error')

    }
export { getLastBar,getExchangeServerTime,getBars,resolveSymbol}
