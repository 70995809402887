export const EXECUTOR_FEE = 10000000000000000;
export const TRADING_FEE=0.0035;
export const TIDEPOSITION_ADDRESS = "0xdF096AFA51DDD5182a10eb7E4d7484ec507bb92d";
export const STORAGE_ADDRESS= "0x0121Dc5feFa476BD6444619ba61557D8Cc378123";
export const STAKE_ADDRESS = "0x84a3880dBB8a7e42c99e9F13C87158fb754B554e";
export const MAXLIQ = 0.03;
export const COLLRATERAL_MIN = 30;
export const COLLRATERAL_MAX = 10000;
export const USDC_PRECISION = 18;
export const USDC_PRECISION_OMNICHAIN=18;
export const INTEREST_PRECISION=6;

export const USDC_ADDRESS = "0xb7f1FE9D45B024EC3049aAF2D1A52cAD9A559a4A";
export const MAX_TRADES_PER_PAIR=3;
// export const MARKET_ADDRESS = "0xBbb5d0Fdb132174CC993207907ce6cf62AFA1F92";
// export const LIMIT_ADDRESS = "0xbd120e7BaD2b62efEEDc234775A504B23BC8C76b";
export const BSCRPCURL = "https://data-seed-prebsc-1-s2.binance.org:8545";
export const TOKENCONVERTOR = {
	["0xb7f1FE9D45B024EC3049aAF2D1A52cAD9A559a4A"]:{//usdc
		[97]:"0xb7f1FE9D45B024EC3049aAF2D1A52cAD9A559a4A",
		[43113]:"0xDbCCe099619D10296b78eDCaAb7a342F57E48812",
		[421613]:"0x23502F3E8557a170FbD90dB2Fa3abe89D0651aFB",
		[420]:"0xd646457233D428121A40762f0e4EDDF51536e781",
		[84531]:"0x8a76Ca7044A0dA49dA73d24D183e876b8c16436B"
	},
	["0xb37f01e763b49661cb0c1d4b7F467d404B72480d"]:{//btc
		[97]:"0xb37f01e763b49661cb0c1d4b7F467d404B72480d",
		[43113]:"0x5a84fe21dC832Ad8Fec412e368c842fCf13eB8FE"
	},
	["0x965cAac30aFE710aB1119072ED9E25a49B0a5231"]:{//eth
		[97]:"0x965cAac30aFE710aB1119072ED9E25a49B0a5231",
		[43113]:"0x257E9FfBC3805676E21d5A6FB19c08ec3eC2F7ED"
	},
	["0x68c44a58a0893f53aeB70fe22f5111c6304Cd621"]:{//bnb
		[97]:"0x68c44a58a0893f53aeB70fe22f5111c6304Cd621",
		[43113]:"0xb308d1334AAdB45dAC7ceAb10cbA9fe8385D59ea"
	},
	["0x47e78eeFB8FB566067cF5624ED13141F6884828C"]:{//doge
		[97]:"0x47e78eeFB8FB566067cF5624ED13141F6884828C",
	},
}
export const MARKET_ADDRESS_LIST ={
	[97]:"0xBbb5d0Fdb132174CC993207907ce6cf62AFA1F92",
	[43113]:"0x7155669C7b2438a4B1f7635E73234235b4016Db0"
}
export const LIMIT_ADDRESS_LIST ={
	[97]:"0xbd120e7BaD2b62efEEDc234775A504B23BC8C76b",
	[43113]:"0x7009Cc7333E7c3E6aA6ebA33414733eb04126911"
}

export const chainTitle ={
	[97]:"BSC",
	[43113]:"Fuji",
	[421613]:"Arbitrum",
	[420]:"Optimism",
	[84531]:"Base"
}

export const chainData ={
	[97]:{
		chainName:'BNB chain [Test]',
		nativeCurrency:{ name: 'Binance Token', decimals: 18, symbol: 'BNB' },
		rpcUrls:['https://data-seed-prebsc-1-s2.binance.org:8545']
	},
	[43113]:{
		chainName:'Fuji [C-Chain]',
		nativeCurrency:{ name: 'Avax Token', decimals: 18, symbol: 'AVAX' },
		rpcUrls:['https://api.avax-test.network/ext/C/rpc']
	},
	[421613]:{
		chainName:'Arbitrum-Goerli [Test]',
		nativeCurrency:{ name: 'GoerliETH Token', decimals: 18, symbol: 'ETH' },
		rpcUrls:['https://arbitrum-goerli.public.blastapi.io']
	},
	[420]:{
		chainName:'Optimism-Goerli [Test]',
		nativeCurrency:{ name: 'Optimism-Goerli Token', decimals: 18, symbol: 'ETH' },
		rpcUrls:['https://goerli.optimism.io']
	},
	[84531]:{
		chainName:'Base-Goerli [Test]',
		nativeCurrency:{ name: 'Base-Goerli Token', decimals: 18, symbol: 'ETH' },
		rpcUrls:['https://goerli.base.org']
	},
}
export const USDC_ADDRESS_LIST = {
	[97]:"0xb7f1FE9D45B024EC3049aAF2D1A52cAD9A559a4A",
	[43113]:"0xDbCCe099619D10296b78eDCaAb7a342F57E48812",
	[421613]:"0x23502F3E8557a170FbD90dB2Fa3abe89D0651aFB",
	[420]:"0xd646457233D428121A40762f0e4EDDF51536e781",
	[84531]:"0x8a76Ca7044A0dA49dA73d24D183e876b8c16436B"
	
}

export const TRADING_ADDRESS_LIST = {
	[97]:"0x87072008edec67b5265c95a8d5bc68211c3de156",
	[43113]:"0xF7A7fbF4EdD64C9D0d8fF1149c1229cd38b2A261",
	[421613]:"0xAe746f50a2828599eF11F078ddd391CFCA450f97",
	[420]:"0x30e03140394ac365af80dda18c0964a9450e6a3e",
	[84531]:"0xD5f9db55fa1dec4Ad6c2b85FC173c3470Cedd24F"//0xA97536BFc0b1f18fA8359Eb05538b49F9dF591dd
}
 
export const ZEROLAYER_ENDPOINT_LIST = {
	[97]:"0x6Fcb97553D41516Cb228ac03FdC8B9a0a9df04A1",
	[43113]:"0x93f54D755A063cE7bB9e6Ac47Eccc8e33411d706",
	[421613]:"0x6aB5Ae6822647046626e83ee6dB8187151E1d5ab",
	[420]:"0xae92d5aD7583AD66E49A0c67BAd18F6ba52dDDc1",
	[84531]:"0x6aB5Ae6822647046626e83ee6dB8187151E1d5ab"
}

export const EXPLORER_LIST = {
	[97]:'https://testnet.bscscan.com',
	[43113]:"https://testnet.snowtrace.io/",
	[421613]:"https://goerli-rollup-explorer.arbitrum.io/",
	[420]:"https://goerli-optimism.etherscan.io/",
	[84531]:"https://goerli.basescan.org/",
	
	
}

export const ZEROLAYER_CHAINID_LIST = {
	[97]:10102,		//bnb
	[43113]:10106,	//fuji
	[421613]:10143,	//arbitrum
	[420]:10132,	// optimas
	[84531]:10160,	// base
	
}

//feed
// 0x085a45cFA0F3Bc653B01009A98706295851fd995