import React, { useState } from "react";
import { tokens } from "constants";
import DataTable from "components/DataTable";
import ClosePositionModal from "components/Modal/ClosePosition";
import SymbolIcon from "assets/images/icons/symbol.svg";

const DesktopTradingPositions = () => {
  const [opened, setOpened] = useState(false);
  const handleToggleOpen = () => setOpened(!opened);

  const cols = [
    "Market",
    "Size",
    "Net value",
    "Entry price",
    "Market price",
    "Liq. price",
    "P&L",
    "Actions",
  ];

  const rows = [
    {
      id: 1,
      token: "btc",
      type: "pay",
      size: "$9.94",
      leverage: 1,
      netValue: "$9.99",
      entryPrice: "30872.00",
      markPrice: "$30723.00",
      liqPrice: null,
      pl: {
        value: -0.05,
        percent: -0.48,
      },
    },
    {
      id: 2,
      token: "btc",
      type: "buy",
      size: "$15.96",
      leverage: 1.6,
      netValue: "$9.99",
      entryPrice: "30749.00",
      markPrice: "$30723.00",
      liqPrice: 49723.55,
      pl: {
        value: 0.01,
        percent: 0.14,
      },
    },
  ];

  const renderRow = (row) => {
    const token = tokens[row.token];
    const types = {
      pay: { label: "Long", color: "text-primary" },
      buy: { label: "Short", color: "text-warning" },
    };

    return (
      <tr className="border-t border-t-border-main last:border-b last:border-b-border-main">
        <td className="py-5 px-5 relative text-sm leading-4">
          <img src={SymbolIcon} className="absolute top-0 left-0" />
          <div className="flex items-center">
            <img src={token.icon} className="w-10 h-10" />
            <div className="ml-3 flex flex-col">
              <span className="uppercase mb-[5px]">{row.token}/USD</span>
              <span className={`${types[row.type].color} text-xs`}>
                {types[row.type].label} {row.leverage}X
              </span>
            </div>
          </div>
        </td>
        <td className="py-5 text-sm">{row.size}</td>
        <td className="py-5 text-sm">{row.netValue}</td>
        <td className="py-5 text-sm">{row.entryPrice}</td>
        <td className="py-5 text-sm">{row.markPrice}</td>
        <td className="py-5 text-sm">{row.liqPrice || "-"}</td>
        <td className="py-5 text-xs">
          <div className="flex flex-col">
            <span
              className={`${
                row.pl.value > 0 ? `text-success` : `text-danger`
              } mb-[5px]`}
            >
              {row.pl.value < 0
                ? `-$${row.pl.value.toString().slice(1)}`
                : `$${row.pl.value}`}
            </span>
            <span className="opacity-50">{row.pl.percent}%</span>
          </div>
        </td>
        <td className="py-5">
          <button
            className="text-sm h-10 px-5 bg-[#121212] rounded-[10px]"
            onClick={handleToggleOpen}
          >
            Close
          </button>
        </td>
      </tr>
    );
  };

  return (
    <div className="">
      <p className="px-5 text-[15px] leading-[18px] py-[30px]">
        Positions {rows.length > 0 && <span>({rows.length})</span>}
      </p>
      <DataTable cols={cols} rows={rows} renderRow={renderRow} />

      <ClosePositionModal open={opened} onClose={handleToggleOpen} />
    </div>
  );
};

export default DesktopTradingPositions;
