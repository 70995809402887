import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import "./style.css";

import React, { useState,useEffect,useCallback, useRef } from 'react';
import { toPng } from 'html-to-image';

import { toast } from 'react-toastify';
import { copyImageToClipboard } from 'copy-image-clipboard'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
};

export default function SharePosition({ 
  isOpen, 
  setOpenModal,
  lastShare,
  entryShare,
  symbolShare,
  sideShare,
  profitShare,
  leverageShare}) {
  //{ open, handleClose }
    
  const ref = useRef(null)

  const [imageData,setImageData] = useState(null)
  const onButtonClick = useCallback(() => {
    if (ref.current === null) {
      console.log('Dont ready')
      return
    }
    toast.info("Preparing image.",{
        icon:({theme, type}) =>  <img src="/assets/image/icon/ic-info.svg"/>
    });
    toPng(ref.current, { cacheBust: true,width:500 })
      .then((dataUrl) => {
        setImageData(dataUrl)
      })
      .catch((err) => {
          toast.error("Dose not support to generate image.",{
              icon:({theme, type}) =>  <img src="/assets/image/icon/ic-error.svg"/>
          });
      })
  }, [ref])
  useEffect(()=>{
    if(isOpen){
      setImageData(null)
    }
  },[isOpen])
  return (
    <Modal
      open={isOpen}
      onClose={()=>{setOpenModal(false)}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"

    >
      <Box sx={style}>
        <div class="sc-hAsxaJ ia-DxTe">
          <div class="sc-yeoIj fYAHzI">
            <div class="sc-caXVBt btrGPJ">Share Card</div>
            <div class="sc-bPyhqo iijVdv" onClick={()=>{setOpenModal(false)}}></div>
          </div>

          <div class="sc-lkwKjF dIxMqX"  >
            {!imageData &&
              <div className="sc-jmNpzm fVmVPEFiat signalcard_container " ref={ref} >
                <div style={{paddingLeft: "10px"}}>
                  <img width="24" className="signalcard_logo" src="tidelogo.svg"/>
                </div>
                <div className="mainarea">
                  <div>
                    <div className="logotitle1">TIDE</div>
                    <div className="logotitle2">FUTURES</div>
                  </div>
                  <div className="sideinfo_row">
                    <div className={"sideinfo_side "+(sideShare=="Long"?"positive":"negative")}>{sideShare}</div>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; <div>{leverageShare}</div>&nbsp;&nbsp;&nbsp; |&nbsp;&nbsp;&nbsp; <div className="shareInfo">{symbolShare}USDT Perpetual</div>
                  </div>
                  <div>
                    <div className={"profitinfo "+(profitShare>0?"positive":"negative")}>%{profitShare.toFixed(2)}</div>
                  </div>
                  <div className="priceInfo">
                    <div className="priceInfo_label">Entry Price</div><div className="priceInfo_value">{entryShare}</div>
                  </div>
                  <div  className="priceInfo">
                    <div className="priceInfo_label">Last Price</div><div className="priceInfo_value">{lastShare}</div>
                  </div>
                  <div className="bottominfo">
                    <div><img className="qrsite" src="/assets/image/qr.png"/></div>
                    <div className="bottominfo_main">
                      <div className="bottominfo_row1">Join me</div>
                      <div className="bottominfo_row2">tide.exchange</div>
                      <div  className="bottominfo_row3">Decentrailsed.No KYC Trading.</div>
                    </div>
                  </div>
                </div>
              </div>
            }
            {imageData && 
            <div class="sc-jmNpzm fVmVPEFiat signalcard_container" ref={ref} >
              <img width="500" src={imageData}/>
            </div>
            }
          </div>
          {!imageData &&
            <div className="sc-lmHNfd visitLink">
              <a onClick={onButtonClick} >Show as image</a>
            </div>
          }
          {imageData &&
            <div className="sc-lmHNfd visitLink">
              <a onClick={()=>{setImageData(null)}} >Show as normal</a>
            </div>
          }
        </div>
      </Box>
    </Modal>
  );
}
