import React from "react";
import SymbolIcon from "assets/images/icons/symbol.svg";

const PositionCard = ({
  onClose,
  leverage,
  symbol,
  tokenimage,
  liq,
  position,
  price,
  profit,
  profit_percent,
  positionSize,
  entryprice,
  positionSizeDai,
}) => {
  const types = {
    true: { label: "Long", color: "text-primary" },
    false: { label: "Short", color: "text-warning" },
  };

  return (
    <div className="bg-[#1B1B1B] rounded-[10px] overflow-hidden border border-border-main">
      <div className="flex items-center justify-between text-sm relative px-5 py-4 border-b border-b-border-main">
        <img src={SymbolIcon} className="w-5 absolute top-0 left-0" alt="" />
        <div className="flex items-center">
          <img src={tokenimage} className="w-5 h-5" />
          <span className="font-semibold ml-3">{symbol}/USD</span>
        </div>
        <span className={types[position.buy].color}>
          {types[position.buy].label} {position.leverage}X
        </span>
      </div>
      <div className="p-4">
        <div className="flex items-center justify-between mb-4">
          <div className="flex flex-col">
            <span className="text-xs opacity-50 mb-[5px] leading-4">PNL</span>
            <span
              className={`text-sm font-semibold ${
                profit > 0 ? "text-success" : "text-danger"
              }`}
            >
              {profit < 0 ? `-$${profit.toString().slice(1)}` : `$${profit}`}
            </span>
          </div>
          <div className="flex flex-col items-end">
            <span className="text-xs opacity-50 mb-[5px] leading-4">ROE</span>
            <span
              className={`text-sm font-semibold ${
                profit > 0 ? "text-success" : "text-danger"
              }`}
            >
              {profit_percent}%
            </span>
          </div>
        </div>
        <div>
          <div className="flex justify-between">
            <div>
              <div className="flex flex-col mb-5">
                <span className="opacity-50 text-xs">Size</span>
                <span className="text-sm">
                  {positionSize}
                  {symbol}
                </span>
              </div>
              <div className="flex flex-col mb-5">
                <span className="opacity-50 text-xs">Entry price</span>
                <span className="text-sm">{entryprice}</span>
              </div>
            </div>
            <div>
              <div className="flex flex-col mb-5">
                <span className="opacity-50 text-xs">Leverage</span>
                <span className="text-sm">{leverage}</span>
              </div>
              <div className="flex flex-col mb-5">
                <span className="opacity-50 text-xs">Index price</span>
                <span className="text-sm">${price}</span>
              </div>
            </div>
            <div>
              <div className="flex flex-col items-end mb-5">
                <span className="opacity-50 text-xs">Collateral</span>
                <span className="text-sm">{positionSizeDai}</span>
              </div>
              <div className="flex flex-col items-end mb-5">
                <span className="opacity-50 text-xs">Liq. price</span>
                <span className="text-sm">${liq || "0.00"}</span>
              </div>
            </div>
          </div>
        </div>
        <button
          className="w-full text-sm h-10 bg-[#252525] rounded"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default PositionCard;
