import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";

const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5,42,56,42161,137,43114,10,250,25,97,43113,420,8453,84531,421613]
});

const RPC_URLS = {
  1:'https://mainnet.infura.io/v3/d5062dd5ea2e402db30a6c458c119532',
  3:'https://ropsten.infura.io/v3/d5062dd5ea2e402db30a6c458c119532',
  4:'https://rinkeby.infura.io/3/d5062dd5ea2e402db30a6c458c119532',
  5:'https://goerli.infura.io/v3/d5062dd5ea2e402db30a6c458c119532',
  42:'https://kovan.infura.io/v3/d5062dd5ea2e402db30a6c458c119532',
  56:'https://bsc-dataseed2.binance.org',
  42161:'https://arb1.arbitrum.io/rpc',
  137:'https://rpc-mainnet.maticvigil.com',
  43114:'https://api.avax.network/ext/bc/C/rpc',
  10:'https://mainnet.optimism.io/',
  250:'https://rpcapi.fantom.network',
  25:'https://evm.cronos.org',
  97:'https://data-seed-prebsc-1-s2.binance.org:8545',
  43113:'https://api.avax-test.network/ext/C/rpc',
  420:'https://goerli.optimism.io',
  421613:'https://arbitrum-goerli.public.blastapi.io',
  84531:'https://goerli.base.org'
}

const walletconnect = new WalletConnectConnector({
  rpc: RPC_URLS,
  infuraId: "d5062dd5ea2e402db30a6c458c119532",
  qrcode: true,
  bridge: "https://bridge.walletconnect.org",
  pollingInterval: 10000
});

const walletlink = new WalletLinkConnector({
  url: `https://mainnet.infura.io/v3/${process.env.INFURA_KEY}`,
  appName: "web3-react-demo"
});

export const connectors = {
  injected: injected,
  walletConnect: walletconnect,
  coinbaseWallet: walletlink
};