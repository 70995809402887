const TOKENS = [
   {
      "name":"Wrapped BNB",
      "symbol":"BNB",
      "decimals":"18",
      "pair":"BNBUSD",
      "pairIndex":"0",
      "address":"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "tideaddress":"0x68c44a58a0893f53aeB70fe22f5111c6304Cd621",
      "chainlink":"0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526",
      "chainlinkdecimals":8,
      "pool":"0xd99c7F6C65857AC913a8f880A4cb84032AB2FC5b",
      "stableFirst":true,
      "tidepool":"0x9eD614c982209dc81fbcA106131BDd761f4B16B6",
      "tidestableFirst":false,
      "bscdecimals":2,
      "icon":"https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/logo.png"
   },
   {
      "name":"Ethereum Token",
      "symbol":"ETH",
      "decimals":"18",
      "pair":"ETHUSD",
      "pairIndex":"1",
      "address":"0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      "tideaddress":"0x965cAac30aFE710aB1119072ED9E25a49B0a5231",
      "chainlink":"0x143db3CEEfbdfe5631aDD3E50f7614B6ba708BA7",
      "chainlinkdecimals":8,
      "pool":"0xEa26B78255Df2bBC31C1eBf60010D78670185bD0",
      "stableFirst":false,
      "tidepool":"0x9CDB61AFF58F05EFA60CeeDd4e239c101bD98DC1",
      "tidestableFirst":false,
      "bscdecimals":2
   },
   {
      "name":"BTCB Token",
      "symbol":"BTC",
      "decimals":"18",
      "pair":"BTCUSD",
      "pairIndex":"2",
      "address":"0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
      "tideaddress":"0xe61795f4E3948d3db57346771385ca12a0fF90b5",
      "chainlink":"0x5741306c21795FdCBb9b265Ea0255F499DFe515C",
      "chainlinkdecimals":8,
      "pool":"0x2dF244535624761f6fCc381CaE3e9b903429d9Ff",
      "stableFirst":false,
      "tidepool":"0xA4a05eF5660aF3561fe57b2D4DdE306D14Ab3a66",
      "tidestableFirst":true,
      "bscdecimals":2
   },
   {
      "name":"Dogecoin",
      "symbol":"DOGE",
      "decimals":"8",
      "pair":"DOGEUSD",
      "pairIndex":"3",
      "address":"0xbA2aE424d960c26247Dd6c32edC70B295c744C43",
      "tideaddress":"0x47e78eeFB8FB566067cF5624ED13141F6884828C",
      "chainlink":"0x963D5e7f285Cc84ed566C486c3c1bC911291be38",
      "chainlinkdecimals":8,
      "pool":"0x3EB4d745DB8e167758475375aD3F2033f6a8332f",
      "stableFirst":true,
      "tidepool":"0x7D753AaCd27D8742E00832291eDc02caef66d82E",
      "tidestableFirst":false,
      "bscdecimals":5
   },
   {
      "name":"Aave Token",
      "symbol":"AAVE",
      "decimals":"18",
      "pair":"AAVEUSD",
      "pairIndex":"4",
      "address":"0xfb6115445bff7b52feb98650c87f44907e58f802",
      "tideaddress":"0x3ad856633f0c63cC7fd1A6B44013Ab2cCde97e6b",
      "chainlink":"0x298619601ebCd58d0b526963Deb2365B485Edc74",
      "chainlinkdecimals":8,
      "pool":"0x0000000000000000000000000000000000000000",
      "stableFirst":true,
      "tidepool":"0x734f47a4cAFF4Fe935B101DaDceB9397e99c391F",
      "tidestableFirst":false,
      "bscdecimals":2
   },
   {
      "name":"Cardano Token",
      "symbol":"ADA",
      "decimals":"18",
      "pair":"ADAUSD",
      "pairIndex":"5",
      "address":"0x3ee2200efb3400fabb9aacf31297cbdd1d435d47",
      "tideaddress":"0x0B7CeE9Dd2dD6B9B98E8Ab321b083EDd1bE1A711",
      "chainlink":"0x5e66a1775BbC249b5D51C13d29245522582E671C",
      "chainlinkdecimals":8,
      "pool":"0x1135C169252cf6ca4022422B5A6eF53A554e4B44",
      "stableFirst":false,
      "tidepool":"0x2dFcb929C98E29671723ecBAf802395dE739E442",
      "tidestableFirst":false,
      "bscdecimals":4
   },
   {
      "name":"BakeryToken",
      "symbol":"BAKE",
      "decimals":"18",
      "pair":"BAKEUSD",
      "pairIndex":"6",
      "address":"0xe02df9e3e622debdd69fb838bb799e3f168902c5",
      "tideaddress":"0xa6a85bE2ef8BA98087Fff645762DA06E9f45E1Ba",
      "chainlink":"0xbe75E0725922D78769e3abF0bcb560d1E2675d5d",
      "chainlinkdecimals":8,
      "pool":"0x0000000000000000000000000000000000000000",
      "stableFirst":true,
      "tidepool":"0x64AFBd9dC2b20d5D21Fb440565C71D34a72B3fb7",
      "tidestableFirst":false,
      "bscdecimals":4
   },
   {
      "name":"Bitcoin Cash Token",
      "symbol":"BCH",
      "decimals":"18",
      "pair":"BCHUSD",
      "pairIndex":"7",
      "address":"0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf",
      "tideaddress":"0x26720115043216fCA969fb8f3Ea3268E8F11A22c",
      "chainlink":"0x887f177CBED2cf555a64e7bF125E1825EB69dB82",
      "chainlinkdecimals":8,
      "pool":"0x5A1b731Bc200e329bCe759D58065D5197cf81b50",
      "stableFirst":true,
      "tidepool":"0x8BB2c41FC843B389cBFF0180eb8e6917EB2feC19",
      "tidestableFirst":false,
      "bscdecimals":2
   },
   {
      "name":"BinaryX",
      "symbol":"BNX",
      "decimals":"18",
      "pair":"BNXUSD",
      "pairIndex":"8",
      "address":"0x8C851d1a123Ff703BD1f9dabe631b69902Df5f97",
      "tideaddress":"0xB5128c78d796d8f93257541212d215251E582111",
      "chainlink":"0xf51492DeD1308Da8195C3bfcCF4a7c70fDbF9daE",
      "chainlinkdecimals":8,
      "pool":"0xebaAe61265Eb78D921E518cf2CAd927CEb6DADB6",
      "stableFirst":true,
      "tidepool":"0x08472023325cd4EA9f3c2C7A2A80A8F3D446336D",
      "tidestableFirst":false,
      "bscdecimals":3
   },
   {
      "name":"PancakeSwap Token",
      "symbol":"Cake",
      "decimals":"18",
      "pair":"CAKEUSD",
      "pairIndex":"9",
      "address":"0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
      "tideaddress":"0x2f2A5d2d5fE2C3570f50475a53dD4243Cf865ee4",
      "chainlink":"0x81faeDDfeBc2F8Ac524327d70Cf913001732224C",
      "chainlinkdecimals":8,
      "pool":"0x177d955dCA80443A09E7a7F5946cA16b8E0dcA1C",
      "stableFirst":false,
      "tidepool":"0x91709fD52f6C0D12A05f6E9c9f19750393ab7b44",
      "tidestableFirst":false,
      "bscdecimals":3
   },
   {
      "name":"Cream",
      "symbol":"CREAM",
      "decimals":"18",
      "pair":"CREAMUSD",
      "pairIndex":"10",
      "address":"0xd4CB328A82bDf5f03eB737f37Fa6B370aef3e888",
      "tideaddress":"0xF647AdAB06C8B54e395EDD64e485b91FDbB10493",
      "chainlink":"0xB8eADfD8B78aDA4F85680eD96e0f50e1B5762b0a",
      "chainlinkdecimals":8,
      "pool":"0x0000000000000000000000000000000000000000",
      "stableFirst":true,
      "tidepool":"0x480A09fA87Fda16c0feC782E6B08387102BE6397",
      "tidestableFirst":true,
      "bscdecimals":2,
      "disable":true
   },
   {
      "name":"DODO bird",
      "symbol":"DODO",
      "decimals":"18",
      "pair":"DODOUSD",
      "pairIndex":"11",
      "address":"0x67ee3cb086f8a16f34bee3ca72fad36f7db929e2",
      "tideaddress":"0xC7c95e25275B80f92179Cf8E45Ef7FBc52D0eAcB",
      "chainlink":"0x2939E0089e61C5c9493C2013139885444c73a398",
      "chainlinkdecimals":8,
      "pool":"0x0000000000000000000000000000000000000000",
      "stableFirst":false,
      "tidepool":"0x7d0820E21C8e1c32a0Db35624466955628661A37",
      "tidestableFirst":true,
      "bscdecimals":4
   },
   {
      "name":"Polkadot Token",
      "symbol":"DOT",
      "decimals":"18",
      "pair":"DOTUSD",
      "pairIndex":"12",
      "address":"0x7083609fce4d1d8dc0c979aab8c869ea2c873402",
      "tideaddress":"0x88cfa33158bE31A9DdC1bCA5a765F04737a3A9bb",
      "chainlink":"0xEA8731FD0685DB8AeAde9EcAE90C4fdf1d8164ed",
      "chainlinkdecimals":8,
      "pool":"0x0000000000000000000000000000000000000000",
      "stableFirst":false,
      "tidepool":"0x6AfdE6e6ed4a6aF64Fc932701b9E992879D7F967",
      "tidestableFirst":false,
      "bscdecimals":3
   },
   {
      "name":"Filecoin",
      "symbol":"FIL",
      "decimals":"18",
      "pair":"FILUSD",
      "pairIndex":"13",
      "address":"0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153",
      "tideaddress":"0x425DA37181f1e9582b01C916ca2380Dc32daA964",
      "chainlink":"0x17308A18d4a50377A4E1C37baaD424360025C74D",
      "chainlinkdecimals":8,
      "pool":"0x0000000000000000000000000000000000000000",
      "stableFirst":false,
      "tidepool":"0xB61225df89badebAEe19a60D61A72e54d85e5757",
      "tidestableFirst":false,
      "bscdecimals":3
   },
   {
      "name":"Frontier Token",
      "symbol":"FRONT",
      "decimals":"18",
      "pair":"FRONTUSD",
      "pairIndex":"14",
      "address":"0x928e55daB735aa8260AF3cEDadA18B5f70C72f1b",
      "tideaddress":"0x90836Bc26C5FD8617d559bE504Fe1bfe56E58c96",
      "chainlink":"0x101E51C0Bc2D2213a9b0c991A991958aAd3fF96A",
      "chainlinkdecimals":8,
      "pool":"0xa73C27f745aD9e16b5ea90252D665F7Bd5E6fc6a",
      "stableFirst":true,
      "tidepool":"0x6fdc8F88DfF0F89b6e913E3C0748b9aE7a2aDb7E",
      "tidestableFirst":false,
      "bscdecimals":4
   },
   {
      "name":"Green Metaverse Token",
      "symbol":"GMT",
      "decimals":"8",
      "pair":"GMTUSD",
      "pairIndex":"15",
      "address":"0x3019bf2a2ef8040c242c9a4c5c4bd4c81678b2a1",
      "tideaddress":"0x0eaAA9eC6078AD56E2Baef7eEE15ea6696998aA3",
      "chainlink":"0x0EAeCDf64C9e8dC709d3B453f921D97c02B4515F",
      "chainlinkdecimals":8,
      "pool":"0x007EC643C7Cc33a70C083fC305c283dd009C8b94",
      "stableFirst":false,
      "tidepool":"0xDdC07FF01e2C9c2854F34943b7cb651c14898CB2",
      "tidestableFirst":false,
      "bscdecimals":4
   },
   {
      "name":"Injective Protocol",
      "symbol":"INJ",
      "decimals":"18",
      "pair":"INJUSD",
      "pairIndex":"16",
      "address":"0xa2b726b1145a4773f68593cf171187d8ebe4d495",
      "tideaddress":"0xD539E65BA1Fd3C72733061bC315d444C170b88f6",
      "chainlink":"0x58b299Fa027E1d9514dBbEeBA7944FD744553d61",
      "chainlinkdecimals":8,
      "pool":"0x08C80b8b9A7a5348cE70c28a47927dEfFF8b550B",
      "stableFirst":true,
      "tidepool":"0xB3131F83Da2616d295EE5f07C03969715fa81218",
      "tidestableFirst":true,
      "bscdecimals":3
   },
   {
      "name":"ChainLink Token",
      "symbol":"LINK",
      "decimals":"18",
      "pair":"LINKUSD",
      "pairIndex":"17",
      "address":"0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd",
      "tideaddress":"0x13789d4B4645FEAee431A974114364621Ed5b6ac",
      "chainlink":"0x1B329402Cb1825C6F30A0d92aB9E2862BE47333f",
      "chainlinkdecimals":8,
      "pool":"0xC0E5CbaD43f90d03E4F54Ac09f3F01350b0c8eef",
      "stableFirst":true,
      "tidepool":"0x368C2648Ff8805cF6dC71A098A5F39d7929a5C8A",
      "tidestableFirst":false,
      "bscdecimals":3
   },
   {
      "name":"Litecoin Token",
      "symbol":"LTC",
      "decimals":"18",
      "pair":"LTCUSD",
      "pairIndex":"18",
      "address":"0x4338665cbb7b2485a8855a139b75d5e34ab0db94",
      "tideaddress":"0x784Af2948c9276A348728045F0e56e32B652dcfd",
      "chainlink":"0x9Dcf949BCA2F4A8a62350E0065d18902eE87Dca3",
      "chainlinkdecimals":8,
      "pool":"0xee9628A9B96B4f89F3e7B87893F9e6b9B3BB6474",
      "stableFirst":false,
      "tidepool":"0xd6fb7E186Cf6E110F2084CCF50fb56d4E06e1D3c",
      "tidestableFirst":false,
      "bscdecimals":2
   },
   {
      "name":"Matic Token",
      "symbol":"MATIC",
      "decimals":"18",
      "pair":"MATICUSD",
      "pairIndex":"19",
      "address":"0xcc42724c6683b7e57334c4e856f4c9965ed682bd",
      "tideaddress":"0x9CA969763f91e84541466E3Ec50B1E1655907485",
      "chainlink":"0x957Eb0316f02ba4a9De3D308742eefd44a3c1719",
      "chainlinkdecimals":8,
      "pool":"0x8816941634999d8B24F17da77619ae78a452D242",
      "stableFirst":true,
      "tidepool":"0x4d508cdca7742edA15ac05C742379f526f9dc62b",
      "tidestableFirst":false,
      "bscdecimals":4
   },
   {
      "name":"Mobox",
      "symbol":"MBOX",
      "decimals":"18",
      "pair":"MBOXUSD",
      "pairIndex":"20",
      "address":"0x3203c9e46ca618c8c1ce5dc67e7e9d75f5da2377",
      "tideaddress":"0x93fEdb9Bb75f74248FF7d792D25095EE78ddc352",
      "chainlink":"0x920667ED2615bc053A4b156aCD48919D83F997cF",
      "chainlinkdecimals":8,
      "pool":"0x0000000000000000000000000000000000000000",
      "stableFirst":false,
      "tidepool":"0xe46bC2288E2686263822250267E2a22C07Be7751",
      "tidestableFirst":false,
      "bscdecimals":3
   },
   {
      "name":"Reef.finance",
      "symbol":"REEF",
      "decimals":"18",
      "pair":"REEFUSD",
      "pairIndex":"21",
      "address":"0xf21768ccbc73ea5b6fd3c687208a7c2def2d966e",
      "tideaddress":"0xCd1cF56b8DBab3B7469A8De16BAC02C0E6AB5265",
      "chainlink":"0x902fA2495a8c5E89F7496F91678b8CBb53226D06",
      "chainlinkdecimals":8,
      "pool":"0xDB8eb9A45aAd07e9BD8Cf128fF455CC3D56842bA",
      "stableFirst":true,
      "tidepool":"0xc23f74Ea42cAb0D93701Ccc432e7ec9aFf1ce5B6",
      "tidestableFirst":true,
      "bscdecimals":6
   },
   {
      "name":"SafePal Token",
      "symbol":"SFP",
      "decimals":"18",
      "pair":"SFPUSD",
      "pairIndex":"22",
      "address":"0xd41fdb03ba84762dd66a0af1a6c8540ff1ba5dfb",
      "tideaddress":"0xBE6eD87792C3278E2D9161Df093348A47583a659",
      "chainlink":"0x4b531A318B0e44B549F3b2f824721b3D0d51930A",
      "chainlinkdecimals":8,
      "pool":"0x4fFD2E056266Ebf67D0037759E1e9170a49bE807",
      "stableFirst":true,
      "tidepool":"0x630d39DD78B4FDaae7A426F90f187F7610638802",
      "tidestableFirst":true,
      "bscdecimals":4
   },
   {
      "name":"Swipe",
      "symbol":"SXP",
      "decimals":"18",
      "pair":"SXPUSD",
      "pairIndex":"23",
      "address":"0x47bead2563dcbf3bf2c9407fea4dc236faba485a",
      "tideaddress":"0xA33FbC042f4a6146e9D4849a1A3cFfcB2180a5EF",
      "chainlink":"0x678AC35ACbcE272651874E782DB5343F9B8a7D66",
      "chainlinkdecimals":8,
      "pool":"0x0000000000000000000000000000000000000000",
      "stableFirst":false,
      "tidepool":"0x3BFc89d26E2C87Cf116cfa4b73413d6C87350259",
      "tidestableFirst":false,
      "bscdecimals":4
   },
   {
      "name":"TRON",
      "symbol":"TRX",
      "decimals":"18",
      "pair":"TRXUSD",
      "pairIndex":"24",
      "address":"0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b",
      "tideaddress":"0xF8d60f5e0E71F6EAF08Ce699C6bd072677410975",
      "chainlink":"0x135deD16bFFEB51E01afab45362D3C4be31AA2B0",
      "chainlinkdecimals":8,
      "pool":"0x0000000000000000000000000000000000000000",
      "stableFirst":false,
      "tidepool":"0x3b0bC9b806F2C10a876d077182173CD875d70632",
      "tidestableFirst":true,
      "bscdecimals":5
   },
   {
      "name":"Trust Wallet",
      "symbol":"TWT",
      "decimals":"18",
      "pair":"TWTUSD",
      "pairIndex":"25",
      "address":"0x4b0f1812e5df2a09796481ff14017e6005508003",
      "tideaddress":"0xA5f334DE7f581101AB1090C6B536a8924A729f8E",
      "chainlink":"0x7671d7EDb66E4C10d5FFaA6a0d8842B5d880F0B3",
      "chainlinkdecimals":8,
      "pool":"0x0000000000000000000000000000000000000000",
      "stableFirst":false,
      "tidepool":"0xDA2D9F9d53C1Bb88302D0ae53c28D4828467151c",
      "tidestableFirst":false,
      "bscdecimals":4
   },
   {
      "name":"XRP Token",
      "symbol":"XRP",
      "decimals":"18",
      "pair":"XRPUSD",
      "pairIndex":"26",
      "address":"0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe",
      "tideaddress":"0x6d632D80948E3db0fD3fdb383A8A2A831d186735",
      "chainlink":"0x4046332373C24Aed1dC8bAd489A04E187833B28d",
      "chainlinkdecimals":8,
      "pool":"0xf44aE8Bb268B72Eba23870184A011c3F78a80e66",
      "stableFirst":false,
      "tidepool":"0x43A25DF0525EB82Dce946a4CC02DBEd1116C9c9A",
      "tidestableFirst":false,
      "bscdecimals":4
   },
   {
      "name":"Venus",
      "symbol":"XVS",
      "decimals":"18",
      "pair":"XVSUSD",
      "pairIndex":"27",
      "address":"0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63",
      "tideaddress":"0xe61A5B518E4a7bB52647e22AD542e3AECC04ae60",
      "chainlink":"0xCfA786C17d6739CBC702693F23cA4417B5945491",
      "chainlinkdecimals":8,
      "pool":"0x534e7362C59746F05F5c0CBA73Ae2b76DdccD974",
      "stableFirst":true,
      "tidepool":"0x9D540c58C6815EC3771A5A810510D7d3242883a4",
      "tidestableFirst":true,
      "bscdecimals":2
   },
   {
      "name":"Afterburner Finance",
      "symbol":"ARB",
      "decimals":"5",
      "pair":"ARBUSD",
      "pairIndex":"28",
      "address":"0xc877304259931d3d42372aecd41338c5bb1f4707",
      "tideaddress":"0x8AD32994E55E7374e8B1adF0ef699a577a184a4E",
      "chainlink":"0x89b5D40A2A904321845eBcBB5E94FDAC8428e6A5",
      "chainlinkdecimals":8,
      "pool":"0x0000000000000000000000000000000000000000",
      "stableFirst":true,
      "tidepool":"0xE607f33A53e42f03fCe4D20aF299063E17b61824",
      "tidestableFirst":false,
      "bscdecimals":4
   }

];
export const tokenMenu1 = [
  {
    name: "Binance-peg USDC",
    symbol: "USDC",
    decimals: 18,
    address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
  },
  {
    name: "Binance USD",
    symbol: "BUSD",
    decimals: 18,
    address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
  },
  {
    name: "Binance-peg USDT",
    symbol: "USDT",
    decimals: 18,
    address: "0x55d398326f99059fF775485246999027B3197955",
  },
];

export const tokenMenu2 = [
      {
         "address":"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
         "tideaddress":"0x68c44a58a0893f53aeB70fe22f5111c6304Cd621",
         "name":"Wrapped BNB",
         "symbol":"WBNB",
         "decimals":"18",
         "bscdecimals":2
      },
      {
         "address":"0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
         "tideaddress":"0x965cAac30aFE710aB1119072ED9E25a49B0a5231",
         "name":"Ethereum Token",
         "symbol":"ETH",
         "decimals":"18",
         "bscdecimals":2
      },
      {
         "address":"0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
         "tideaddress":"0xe61795f4E3948d3db57346771385ca12a0fF90b5",
         "name":"BTCB Token",
         "symbol":"BTCB",
         "decimals":"18",
         "bscdecimals":2
      },
      {
         "address":"0xbA2aE424d960c26247Dd6c32edC70B295c744C43",
         "tideaddress":"0x47e78eeFB8FB566067cF5624ED13141F6884828C",
         "name":"Dogecoin",
         "symbol":"DOGE",
         "decimals":"8",
         "bscdecimals":5
      },
      {
         "address":"0xfb6115445bff7b52feb98650c87f44907e58f802",
         "tideaddress":"0x3ad856633f0c63cC7fd1A6B44013Ab2cCde97e6b",
         "name":"Aave Token",
         "symbol":"AAVE",
         "decimals":"18",
         "bscdecimals":2
      },
      {
         "address":"0x3ee2200efb3400fabb9aacf31297cbdd1d435d47",
         "tideaddress":"0x0B7CeE9Dd2dD6B9B98E8Ab321b083EDd1bE1A711",
         "name":"Cardano Token",
         "symbol":"ADA",
         "decimals":"18",
         "bscdecimals":4
      },
      {
         "address":"0xe02df9e3e622debdd69fb838bb799e3f168902c5",
         "tideaddress":"0xa6a85bE2ef8BA98087Fff645762DA06E9f45E1Ba",
         "name":"BakeryToken",
         "symbol":"BAKE",
         "decimals":"18",
         "bscdecimals":4
      },
      {
         "address":"0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf",
         "tideaddress":"0x26720115043216fCA969fb8f3Ea3268E8F11A22c",
         "name":"Bitcoin Cash Token",
         "symbol":"BCH",
         "decimals":"18",
         "bscdecimals":2
      },
      {
         "address":"0x8C851d1a123Ff703BD1f9dabe631b69902Df5f97",
         "tideaddress":"0xB5128c78d796d8f93257541212d215251E582111",
         "name":"BinaryX",
         "symbol":"BNX",
         "decimals":"18",
         "bscdecimals":3
      },
      {
         "address":"0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
         "tideaddress":"0x2f2A5d2d5fE2C3570f50475a53dD4243Cf865ee4",
         "name":"PancakeSwap Token",
         "symbol":"Cake",
         "decimals":"18",
         "bscdecimals":3
      },
      {
         "address":"0xd4CB328A82bDf5f03eB737f37Fa6B370aef3e888",
         "tideaddress":"0xF647AdAB06C8B54e395EDD64e485b91FDbB10493",
         "name":"Cream",
         "symbol":"CREAM",
         "decimals":"18",
         "bscdecimals":4
      },
      {
         "address":"0x67ee3cb086f8a16f34bee3ca72fad36f7db929e2",
         "tideaddress":"0xC7c95e25275B80f92179Cf8E45Ef7FBc52D0eAcB",
         "name":"DODO bird",
         "symbol":"DODO",
         "decimals":"18",
         "bscdecimals":3
      },
      {
         "address":"0x7083609fce4d1d8dc0c979aab8c869ea2c873402",
         "tideaddress":"0x88cfa33158bE31A9DdC1bCA5a765F04737a3A9bb",
         "name":"Polkadot Token",
         "symbol":"DOT",
         "decimals":"18",
         "bscdecimals":3
      },
      {
         "address":"0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153",
         "tideaddress":"0x425DA37181f1e9582b01C916ca2380Dc32daA964",
         "name":"Filecoin",
         "symbol":"FIL",
         "decimals":"18",
         "bscdecimals":4
      },
      {
         "address":"0x928e55daB735aa8260AF3cEDadA18B5f70C72f1b",
         "tideaddress":"0x90836Bc26C5FD8617d559bE504Fe1bfe56E58c96",
         "name":"Frontier Token",
         "symbol":"FRONT",
         "decimals":"18",
         "bscdecimals":4
      },
      {
         "address":"0x3019bf2a2ef8040c242c9a4c5c4bd4c81678b2a1",
         "tideaddress":"0x0eaAA9eC6078AD56E2Baef7eEE15ea6696998aA3",
         "name":"Green Metaverse Token",
         "symbol":"GMT",
         "decimals":"8",
         "bscdecimals":4
      },
      {
         "address":"0xa2b726b1145a4773f68593cf171187d8ebe4d495",
         "tideaddress":"0xD539E65BA1Fd3C72733061bC315d444C170b88f6",
         "name":"Injective Protocol",
         "symbol":"INJ",
         "decimals":"18",
         "bscdecimals":3
      },
      {
         "address":"0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd",
         "tideaddress":"0x13789d4B4645FEAee431A974114364621Ed5b6ac",
         "name":"ChainLink Token",
         "symbol":"LINK",
         "decimals":"18",
         "bscdecimals":3
      },
      {
         "address":"0x4338665cbb7b2485a8855a139b75d5e34ab0db94",
         "tideaddress":"0x784Af2948c9276A348728045F0e56e32B652dcfd",
         "name":"Litecoin Token",
         "symbol":"LTC",
         "decimals":"18",
         "bscdecimals":2
      },
      {
         "address":"0xcc42724c6683b7e57334c4e856f4c9965ed682bd",
         "tideaddress":"0x9CA969763f91e84541466E3Ec50B1E1655907485",
         "name":"Matic Token",
         "symbol":"MATIC",
         "decimals":"18",
         "bscdecimals":4
      },
      {
         "address":"0x3203c9e46ca618c8c1ce5dc67e7e9d75f5da2377",
         "tideaddress":"0x93fEdb9Bb75f74248FF7d792D25095EE78ddc352",
         "name":"Mobox",
         "symbol":"MBOX",
         "decimals":"18",
         "bscdecimals":3
      },
      {
         "address":"0xf21768ccbc73ea5b6fd3c687208a7c2def2d966e",
         "tideaddress":"0xCd1cF56b8DBab3B7469A8De16BAC02C0E6AB5265",
         "name":"Reef.finance",
         "symbol":"REEF",
         "decimals":"18",
         "bscdecimals":6
      },
      {
         "address":"0xd41fdb03ba84762dd66a0af1a6c8540ff1ba5dfb",
         "tideaddress":"0xBE6eD87792C3278E2D9161Df093348A47583a659",
         "name":"SafePal Token",
         "symbol":"SFP",
         "decimals":"18",
         "bscdecimals":4
      },
      {
         "address":"0x47bead2563dcbf3bf2c9407fea4dc236faba485a",
         "tideaddress":"0xA33FbC042f4a6146e9D4849a1A3cFfcB2180a5EF",
         "name":"Swipe",
         "symbol":"SXP",
         "decimals":"18",
         "bscdecimals":4
      },
      {
         "address":"0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b",
         "tideaddress":"0xF8d60f5e0E71F6EAF08Ce699C6bd072677410975",
         "name":"TRON",
         "symbol":"TRX",
         "decimals":"18",
         "bscdecimals":5
      },
      {
         "address":"0x4b0f1812e5df2a09796481ff14017e6005508003",
         "tideaddress":"0xA5f334DE7f581101AB1090C6B536a8924A729f8E",
         "name":"Trust Wallet",
         "symbol":"TWT",
         "decimals":"18",
         "bscdecimals":4
      },
      {
         "address":"0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe",
         "tideaddress":"0x6d632D80948E3db0fD3fdb383A8A2A831d186735",
         "name":"XRP Token",
         "symbol":"XRP",
         "decimals":"18",
         "bscdecimals":4
      },
      {
         "address":"0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63",
         "tideaddress":"0xe61A5B518E4a7bB52647e22AD542e3AECC04ae60",
         "name":"Venus",
         "symbol":"XVS",
         "decimals":"18",
         "bscdecimals":2
      },
      {
         "address":"0xc877304259931d3d42372aecd41338c5bb1f4707",
         "tideaddress":"0x8AD32994E55E7374e8B1adF0ef699a577a184a4E",
         "name":"Afterburner Finance",
         "symbol":"ARB",
         "decimals":"5",
         "bscdecimals":4
      }

   ]

export function tokenInfo(){
  return TOKENS;
}